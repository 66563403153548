import React, { useContext, useState, useEffect } from 'react'; // Combine useContext, useState, and useEffect
import './TopBar.css';
import { PiPhoneListThin, PiEnvelopeOpenThin } from 'react-icons/pi';
import AuthContext from '../context/AuthContext';
import usericon from '../assets/icons/user.svg';



const TopBar = () => {
  const { user, logout } = useContext(AuthContext);
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

   // Adding the Google Tag (gtag.js) script dynamically
   useEffect(() => {
    // Load gtag.js script
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtag/js?id=AW-16529115435';
    document.head.appendChild(script);

    // Initialize the gtag function and configuration after script has loaded
    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', 'AW-16529115435');
    };

    // Google Tag conversion tracking for the '/thank-you' page
    window.addEventListener('load', function () {
      if (window.location.pathname.includes('/thank-you')) {
        window.gtag('event', 'conversion', {
          send_to: 'AW-16529115435/gk_kCLvT2NUZEKuS2ck9',
        });
      }
    });
  }, []);

  return (
    <div className="top-bar">
      <div className="top-bar-container">
        <div className="contact-info">
          <span>
            <a href="tel:+19058975000" className="contact-link">
              <PiPhoneListThin className="icon-adjustment" /> <span className="contact-text">(905) 897-5000</span>
            </a>
          </span>
          <span>
            <a href="/contact" className="contact-link">
              <PiEnvelopeOpenThin className="icon-adjustment" /> <span className="contact-text">info@modernsolution.ca</span>
            </a>
          </span>
        </div>

        <div className="auth-links">
          {user && (
            <>
              {/* For larger screens */}
              <div className="desktop-auth">
                <span className="auth-link">Logged in as {user.username}</span>
                <button onClick={logout} className="auth-link logout-button">Logout</button>
              </div>

              {/* For mobile screens */}
              <div className="mobile-auth">
                <img
                  src={usericon}
                  alt="User Icon"
                  className="user-icon"
                  onClick={toggleDropdown}
                />
                {isDropdownOpen && (
                  <div className="dropdown-menu">
                    <span className="dropdown-item">Logged in as {user.username}</span>
                    <button onClick={logout} className="dropdown-item logout-button">Logout</button>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default TopBar;
