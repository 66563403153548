import React, { useState, useEffect, useContext, useRef } from 'react';

import axios from '../services/axios';

import './Listings.css';

import AuthContext from '../context/AuthContext';

import { ReactComponent as BedroomIcon } from '../assets/icons/bedroom-3.svg';

import { ReactComponent as BathroomIcon } from '../assets/icons/bathroom.svg';

import { ReactComponent as SquareFeetIcon } from '../assets/icons/squarefeet.svg';

import ListingDetail from './ListingDetail';

import { FaShareAlt } from 'react-icons/fa';

import { FaSearch } from 'react-icons/fa';

import SharePopup from './SharePopup';  // Import the new SharePopup component

import './SharePopup.css';



const geocodeCache = new Map();


const Listings = () => {

  const [currentPage, setCurrentPage] = useState(1);

  const [priceRange, setPriceRange] = useState({ min: 0, max: 10000000 });

  const [showFilters, setShowFilters] = useState(false);

  const [showMobileFilters, setShowMobileFilters] = useState(false);

  const toggleMobileFilters = () => {

    setShowMobileFilters(!showMobileFilters);

  };

  const [selectedNeighborhood, setSelectedNeighborhood] = useState('All');

const [neighborhoodSearchQuery, setNeighborhoodSearchQuery] = useState('');

const [filteredNeighborhoods, setFilteredNeighborhoods] = useState([]);

const [loadingPagination, setLoadingPagination] = useState(false);



  const [selectedCity, setSelectedCity] = useState('All');

  const [shareText, setShareText] = useState('Share');

  const [selectedBedrooms, setSelectedBedrooms] = useState('Any');

  const [selectedBathrooms, setSelectedBathrooms] = useState('Any');

  const [searchQuery, setSearchQuery] = useState('');

  const [hoveredListing, setHoveredListing] = useState(null);

  const [selectedListing, setSelectedListing] = useState(null);

  const [listings, setListings] = useState([]);

  const [filteredListings, setFilteredListings] = useState([]);

  const [error, setError] = useState(null);

  const searchTimeoutRef = useRef(null);  // Use ref to store timeout ID


  const [loading, setLoading] = useState(true);

  const [filteredCities, setFilteredCities] = useState([]);


  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const [totalListings, setTotalListings] = useState(0);

  const [initialCities] = useState([

    'Adjala-Tosorontio', 'Ajax', 'Aurora', 'Barrie', 'Brampton', 'Brock', 'Burlington',

    'Caledon', 'Cambridge', 'Centre Wellington', 'Chatsworth', 'Clarington', 'Collingwood',

    'East Gwillimbury', 'East Luther Grand Valley', 'Erin', 'Essa', 'Fort Erie',

    'Galway-Cavendish and Harvey', 'Gananoque', 'Georgian Bay', 'Georgian Bluffs',

    'Georgina', 'Grimsby', 'Guelph', 'Guelph/Eramosa', 'Haldimand', 'Halton Hills',

    'Hamilton', 'Innisfil', 'Kawartha Lakes', 'King', 'Kingston', 'Kitchener',

    'Lincoln', 'London', 'Markham', 'Midland', 'Milton', 'Mississauga', 'New Tecumseth',

    'Newmarket', 'Niagara Falls', 'Niagara-on-the-Lake', 'Oakville', 'Orangeville',

    'Orillia', 'Oro-Medonte', 'Oshawa', 'Ottawa', 'Owen Sound', 'Penetanguishene',

    'Peterborough', 'Pickering', 'Port Colborne', 'Scugog', 'Severn', 'Shelburne',

    'Tiny', 'Toronto', 'Vaughan', 'Wainfleet', 'Wasaga Beach', 'Waterloo', 'Welland',

    'Whitby', 'Whitchurch-Stouffville', 'Woolwich'

  ]);


  

  

  

  const [initialBedrooms] = useState([...Array(9).keys()].map((num) => (num + 1).toString()));

  const [initialBathrooms] = useState([...Array(10).keys()].map((num) => (num + 1).toString()));

  const [initialZoomLevel] = useState(8);

  const mapRef = useRef(null);

  const mapInstance = useRef(null);

  const markerClusterer = useRef(null);

  const listingsPerPage =  20;

  const maxListingsToShow = 100;

  const maxTotalListings = 70000;

  const dropdownRef = useRef(null);


  



  const { user } = useContext(AuthContext);


  const neighborhoods = [

    { name: 'Zurich', city: 'Bluewater' },

    { name: 'Yorkdale-Glen Park', city: 'Toronto' },

    { name: 'York University Heights', city: 'Toronto' },

    { name: 'Yonge-St. Clair', city: 'Toronto' },

    { name: 'Yonge-Eglinton', city: 'Toronto' },

    { name: 'Yeoville', city: 'Hamilton' },

    { name: 'Wyevale', city: 'Tiny' },

    { name: 'Wyecombe', city: 'Norfolk' },

    { name: 'Wyebridge', city: 'Tiny' },

    { name: 'Wychwood', city: 'Toronto' },

    { name: 'Woodville', city: 'Kawartha Lakes' },

    { name: 'Woodstock - South', city: 'Tillsonburg' },

    { name: 'Woodlands', city: 'Pickering' },

    { name: 'Woodland Hill', city: 'Newmarket' },

    { name: 'Woodbine-Lumsden', city: 'Toronto' },

    { name: 'Woodbine Corridor', city: 'Toronto' },

    { name: 'Woburn', city: 'Toronto' },

    { name: 'Wismer', city: 'Markham' },

    { name: 'Winona Park', city: 'Hamilton' },

    { name: 'Winona', city: 'Hamilton' },

    { name: 'Wingham', city: 'North Huron' },

    { name: 'Windsor', city: 'Windsor' },

    { name: 'Windham Centre', city: 'Norfolk' },

    { name: 'Windfields', city: 'Oshawa' },

    { name: 'Wilsonville', city: 'Norfolk' },

    { name: 'Willowridge-Martingrove-Richview', city: 'Toronto' },

    { name: 'Willowdale West', city: 'Toronto' },

    { name: 'Willowdale East', city: 'Toronto' },

    { name: 'Willmott', city: 'Milton' },

    { name: 'Williamsburg', city: 'Whitby' },

    { name: 'Wheatley', city: 'Chatham-Kent' },

    { name: 'Wexford-Maryvale', city: 'Toronto' },

    { name: 'Weston-Pellam Park', city: 'Toronto' },

    { name: 'Weston', city: 'Toronto' },

    { name: 'Westminster-Branson', city: 'Toronto' },

    { name: 'Westgate', city: 'Brampton' },

    { name: 'Westdale', city: 'Hamilton' },

    { name: 'Westcliffe', city: 'Hamilton' },

    { name: 'Westbrook', city: 'Richmond Hill' },

    { name: 'West Woodbridge', city: 'Vaughan' },

    { name: 'West Willow Woods', city: 'Guelph' },

    { name: 'West Shore', city: 'Pickering' },

    { name: 'West Shore', city: 'Severn' },

    { name: 'West Oak Trails', city: 'Oakville' },

    { name: 'West Lorne', city: 'West Elgin' },

    { name: 'West Humber-Clairville', city: 'Toronto' },

    { name: 'West Hill', city: 'Toronto' },

    { name: 'West Carleton', city: 'Ottawa' },

    { name: 'West Bayfield', city: 'Barrie' },

    { name: 'Wellington', city: 'Barrie' },

    { name: 'Wellington', city: 'Prince Edward County' },

    { name: 'Waverley', city: 'Guelph' },

    { name: 'Waverley', city: 'Tay' },

    { name: 'Waubaushene', city: 'Tay' },

    { name: 'Watford', city: 'Warwick' },

    { name: 'Waterfront Communities C8', city: 'Toronto' },

    { name: 'Waterfront Communities C1', city: 'Toronto' },

    { name: 'Waterford', city: 'Norfolk' },

    { name: 'Waterdown', city: 'Hamilton' },

    { name: 'Washago', city: 'Severn' },

    { name: 'Wasaga Beach', city: 'Wasaga Beach' },

    { name: 'Warminster', city: 'Oro-Medonte' },

    { name: 'Warkworth', city: 'Trent Hills' },

    { name: 'Wardsville', city: 'Southwest Middlesex' },

    { name: 'Walton', city: 'Huron East' },

    { name: 'Walsingham', city: 'Norfolk' },

    { name: 'Walsh', city: 'Norfolk' },

    { name: 'Wallacetown', city: 'Dutton/Dunwich' },

    { name: 'Wallaceburg', city: 'Chatham-Kent' },

    { name: 'Walker', city: 'Milton' },

    { name: 'Walden', city: 'Lambton Shores' },

    { name: 'Vittoria', city: 'Norfolk' },

    { name: 'Virginia', city: 'Georgina' },

    { name: 'Vinegar Hill', city: 'Markham' },

    { name: 'Vincent', city: 'Hamilton' },

    { name: 'Villages of Glancaster', city: 'Hamilton' },

    { name: 'Village Green-South Unionville', city: 'Markham' },

    { name: 'Village East', city: 'Pickering' },

    { name: 'Village', city: 'Guelph' },

    { name: 'Villa Nova', city: 'Norfolk' },

    { name: 'Vienna', city: 'Bayham' },

    { name: 'Victoria Village', city: 'Toronto' },

    { name: 'Victoria Square', city: 'Markham' },

    { name: 'Victoria Manor-Jennings Gate', city: 'Markham' },

    { name: 'Victoria Harbour', city: 'Tay' },

    { name: 'Vellore Village', city: 'Vaughan' },

    { name: 'Vaughan Grove', city: 'Vaughan' },

    { name: 'Vaughan Corporate Centre', city: 'Vaughan' },

    { name: 'Varna', city: 'Bluewater' },

    { name: 'Vanier', city: 'Oshawa' },

    { name: 'Vanessa', city: 'Norfolk' },

    { name: 'Valley East', city: 'Greater Sudbury' },

    { name: 'Vales of Castlemore North', city: 'Brampton' },

    { name: 'Vales of Castlemore', city: 'Brampton' },

    { name: 'Uxbridge', city: 'Uxbridge' },

    { name: 'Uptown Core', city: 'Oakville' },

    { name: 'Uptown', city: 'Burlington' },

    { name: 'Uplands', city: 'Vaughan' },

    { name: 'University', city: 'Toronto' },

    { name: 'Unionville', city: 'Markham' },

    { name: 'Union', city: 'Central Elgin' },

    { name: 'Tyandaga', city: 'Burlington' },

    { name: 'Two Rivers', city: 'Guelph' },

    { name: 'Twenty Place', city: 'Hamilton' },

    { name: 'Turkey Point', city: 'Norfolk' },

    { name: 'Trinity-Bellwoods', city: 'Toronto' },

    { name: 'Trenholme', city: 'Hamilton' },

    { name: 'Trafalgar', city: 'Milton' },

    { name: 'Townsend', city: 'Norfolk' },

    { name: 'Town Centre', city: 'Pickering' },

    { name: 'Tottenham', city: 'New Tecumseth' },

    { name: 'Toronto Gore Rural Estate', city: 'Brampton' },

    { name: 'Timberlea', city: 'Milton' },

    { name: 'Tillsonburg', city: 'Tillsonburg' },

    { name: 'Tilbury', city: 'Chatham-Kent' },

    { name: 'Thornton', city: 'Essa' },

    { name: 'Thornlea', city: 'Markham' },

    { name: 'Thornhill', city: 'Markham' },

    { name: 'Thorner', city: 'Hamilton' },

    { name: 'Thorndale', city: 'Thames Centre' },

    { name: 'Thorncliffe Park', city: 'Toronto' },

    { name: 'Thornbury', city: 'Blue Mountains' },

    { name: 'Thistletown-Beaumonde Heights', city: 'Toronto' },

    { name: 'Thedford', city: 'Lambton Shores' },

    { name: 'The Beaches', city: 'Toronto' },

    { name: 'Thamesville', city: 'Chatham-Kent' },

    { name: 'Thamesford', city: 'Zorra' },

    { name: 'Templemead', city: 'Hamilton' },

    { name: 'Teeterville', city: 'Norfolk' },

    { name: 'Taunton North', city: 'Whitby' },

    { name: 'Taunton', city: 'Oshawa' },

    { name: 'Tansley', city: 'Burlington' },

    { name: 'Tam O\'Shanter-Sullivan', city: 'Toronto' },

    { name: 'Talbotville', city: 'Southwold' },

    { name: 'SW', city: 'St. Thomas' },

    { name: 'SW', city: 'Strathroy-Caradoc' },

    { name: 'SW', city: 'Chatham-Kent' },

    { name: 'Sutton & Jackson\'s Point', city: 'Georgina' },

    { name: 'Sunninghill', city: 'Hamilton' },

    { name: 'Sunnidale', city: 'Barrie' },

    { name: 'Sunderland', city: 'Brock' },

    { name: 'Summerhill Estates', city: 'Newmarket' },

    { name: 'Sugarbush', city: 'Oro-Medonte' },

    { name: 'Sudbury', city: 'Greater Sudbury' },

    { name: 'Stroud', city: 'Innisfil' },

    { name: 'Streetsville', city: 'Mississauga' },

    { name: 'Strathcona', city: 'Hamilton' },

    { name: 'Straffordville', city: 'Bayham' },

    { name: 'Stouffville', city: 'Whitchurch-Stouffville' },

    { name: 'Stoney Creek Mountain', city: 'Hamilton' },

    { name: 'Stoney Creek Industrial', city: 'Hamilton' },

    { name: 'Stoney Creek', city: 'Hamilton' },

    { name: 'Stonehaven-Wyndham', city: 'Newmarket' },

    { name: 'Stonegate-Queensway', city: 'Toronto' },

    { name: 'Stipley', city: 'Hamilton' },

    { name: 'Stinson', city: 'Hamilton' },

    { name: 'Stewarttown', city: 'Halton Hills' },

    { name: 'Stevensville', city: 'Fort Erie' },

    { name: 'Stephen Twp', city: 'South Huron' },

    { name: 'Steeles West Industrial', city: 'Vaughan' },

    { name: 'Steeles', city: 'Toronto' },

    { name: 'Stayner', city: 'Clearview' },

    { name: 'St. Williams', city: 'Norfolk' },

    { name: 'St. Joseph', city: 'Bluewater' },

    { name: 'St. Clair', city: 'Hamilton' },

    { name: 'St. Clair', city: 'St. Clair' },

    { name: 'St. Andrew-Windfields', city: 'Toronto' },

    { name: 'Springfield', city: 'Malahide' },

    { name: 'Sparta', city: 'Central Elgin' },

    { name: 'Southwold Town', city: 'Southwold' },

    { name: 'Southgate', city: 'Brampton' },

    { name: 'Southam', city: 'Hamilton' },

    { name: 'South ZZ', city: 'London' },

    { name: 'South Y', city: 'London' },

    { name: 'South X', city: 'London' },

    { name: 'South West', city: 'Ajax' },

    { name: 'South W', city: 'London' },

    { name: 'South V', city: 'London' },

    { name: 'South U', city: 'London' },

    { name: 'South T', city: 'London' },

    { name: 'South Shore', city: 'Barrie' },

    { name: 'South S', city: 'London' },

    { name: 'South Riverdale', city: 'Toronto' },

    { name: 'South Richvale', city: 'Richmond Hill' },

    { name: 'South R', city: 'London' },

    { name: 'South Q', city: 'London' },

    { name: 'South Parkdale', city: 'Toronto' },

    { name: 'South P', city: 'London' },

    { name: 'South O', city: 'London' },

    { name: 'South N', city: 'London' },

    { name: 'South MM', city: 'London' },

    { name: 'South Marysburgh', city: 'Prince Edward County' },

    { name: 'South M', city: 'London' },

    { name: 'South L', city: 'London' },

    { name: 'South K', city: 'London' },

    { name: 'South J', city: 'London' },

    { name: 'South I', city: 'London' },

    { name: 'South H', city: 'London' },

    { name: 'South GG', city: 'London' },

    { name: 'South G', city: 'London' },

    { name: 'South F', city: 'London' },

    { name: 'South East', city: 'Ajax' },

    { name: 'South E', city: 'London' },

    { name: 'South Dumfries', city: 'Brant' },

    { name: 'South D', city: 'London' },

    { name: 'South C', city: 'London' },

    { name: 'South Bruce Peninsula', city: 'South Bruce Peninsula' },

    { name: 'South Bruce', city: 'South Bruce' },

    { name: 'Sophiasburgh', city: 'Prince Edward County' },

    { name: 'Sonoma Heights', city: 'Vaughan' },

    { name: 'Snow Valley', city: 'Springwater' },

    { name: 'Snelgrove', city: 'Brampton' },

    { name: 'Snake Island', city: 'Georgina Islands' },

    { name: 'Singhampton', city: 'Clearview' },

    { name: 'Simcoe', city: 'Norfolk' },

    { name: 'Shoreacres', city: 'Burlington' },

    { name: 'Sherwood-Amberglen', city: 'Markham' },

    { name: 'Sherwood', city: 'Hamilton' },

    { name: 'Sheridan Park', city: 'Mississauga' },

    { name: 'Sheridan', city: 'Mississauga' },

    { name: 'Sheldon', city: 'Hamilton' },

    { name: 'Shelburne', city: 'Shelburne' },

    { name: 'Shedden', city: 'Southwold' },

    { name: 'Sharon', city: 'East Gwillimbury' },

    { name: 'Shanty Bay', city: 'Oro-Medonte' },

    { name: 'Scott', city: 'Milton' },

    { name: 'Schomberg', city: 'King' },

    { name: 'Scarborough Village', city: 'Toronto' },

    { name: 'Saugeen Shores', city: 'Saugeen Shores' },

    { name: 'Sarnia', city: 'Sarnia' },

    { name: 'Sandy Hollow', city: 'Barrie' },

    { name: 'Sandringham-Wellington North', city: 'Brampton' },

    { name: 'Sandringham-Wellington', city: 'Brampton' },

    { name: 'Samac', city: 'Oshawa' },

    { name: 'Rymal', city: 'Hamilton' },

    { name: 'Ryckmans', city: 'Hamilton' },

    { name: 'Rustic', city: 'Toronto' },

    { name: 'Rushdale', city: 'Hamilton' },

    { name: 'Rural Woodhouse', city: 'Norfolk' },

    { name: 'Rural Windham', city: 'Norfolk' },

    { name: 'Rural Whitchurch-Stouffville', city: 'Whitchurch-Stouffville' },

    { name: 'Rural Whitby', city: 'Whitby' },

    { name: 'Rural West Grey', city: 'West Grey' },

    { name: 'Rural West Elgin', city: 'West Elgin' },

    { name: 'Rural Wellington North', city: 'Wellington North' },

    { name: 'Rural Verulam', city: 'Kawartha Lakes' },

    { name: 'Rural Vaughan', city: 'Vaughan' },

    { name: 'Rural Uxbridge', city: 'Uxbridge' },

    { name: 'Rural Trent Hills', city: 'Trent Hills' },

    { name: 'Rural Tiny', city: 'Tiny' },

    { name: 'Rural Thames Centre', city: 'Thames Centre' },

    { name: 'Rural Tay', city: 'Tay' },

    { name: 'Rural Strathroy Caradoc', city: 'Strathroy-Caradoc' },

    { name: 'Rural Stoney Creek', city: 'Hamilton' },

    { name: 'Rural Springwater', city: 'Springwater' },

    { name: 'Rural Southwold', city: 'Southwold' },

    { name: 'Rural Southwest Middlesex', city: 'Southwest Middlesex' },

    { name: 'Rural Southgate', city: 'Southgate' },

    { name: 'Rural Somerville', city: 'Kawartha Lakes' },

    { name: 'Rural Smith-Ennismore-Lakefield', city: 'Smith-Ennismore-Lakefield' },

    { name: 'Rural Severn', city: 'Severn' },

    { name: 'Rural Scugog', city: 'Scugog' },

    { name: 'Rural Richmond Hill', city: 'Richmond Hill' },

    { name: 'Rural Ramara', city: 'Ramara' },

    { name: 'Rural Puslinch', city: 'Puslinch' },

    { name: 'Rural Port Hope', city: 'Port Hope' },

    { name: 'Rural Pickering', city: 'Pickering' },

    { name: 'Rural Otonabee-South Monaghan', city: 'Otonabee-South Monaghan' },

    { name: 'Rural Oshawa', city: 'Oshawa' },

    { name: 'Rural Oro-Medonte', city: 'Oro-Medonte' },

    { name: 'Rural Orford', city: 'Chatham-Kent' },

    { name: 'Rural Ops', city: 'Kawartha Lakes' },

    { name: 'Rural Oakville', city: 'Oakville' },

    { name: 'Rural Norwich', city: 'Norwich' },

    { name: 'Rural North Middlesex', city: 'North Middlesex' },

    { name: 'Rural North Kawartha', city: 'North Kawartha' },

    { name: 'Rural New Tecumseth', city: 'New Tecumseth' },

    { name: 'Rural Mulmur', city: 'Mulmur' },

    { name: 'Rural Mono', city: 'Mono' },

    { name: 'Rural Minto', city: 'Minto' },

    { name: 'Rural Middleton', city: 'Norfolk' },

    { name: 'Rural Middlesex Centre', city: 'Middlesex Centre' },

    { name: 'Rural Melancthon', city: 'Melancthon' },

    { name: 'Rural Meaford', city: 'Meaford' },

    { name: 'Rural Markham', city: 'Markham' },

    { name: 'Rural Mariposa', city: 'Kawartha Lakes' },

    { name: 'Rural Mapleton', city: 'Mapleton' },

    { name: 'Rural Manvers', city: 'Kawartha Lakes' },

    { name: 'Rural Malahide', city: 'Malahide' },

    { name: 'Rural Laxton', city: 'Kawartha Lakes' },

    { name: 'Rural King', city: 'King' },

    { name: 'Rural Innisfil', city: 'Innisfil' },

    { name: 'Rural Houghton', city: 'Norfolk' },

    { name: 'Rural Havelock-Belmont-Methuen', city: 'Havelock-Belmont-Methuen' },

    { name: 'Rural Halton Hills', city: 'Halton Hills' },

    { name: 'Rural Guelph/Eramosa', city: 'Guelph/Eramosa' },

    { name: 'Rural Grey Highlands', city: 'Grey Highlands' },

    { name: 'Rural Glanbrook', city: 'Hamilton' },

    { name: 'Rural Georgian Bluffs', city: 'Georgian Bluffs' },

    { name: 'Rural Galway-Cavendish and Harvey', city: 'Galway-Cavendish and Harvey' },

    { name: 'Rural Flamborough', city: 'Hamilton' },

    { name: 'Rural Fenelon', city: 'Kawartha Lakes' },

    { name: 'Rural Essa', city: 'Essa' },

    { name: 'Rural Erin', city: 'Erin' },

    { name: 'Rural Emily', city: 'Kawartha Lakes' },

    { name: 'Rural Eldon', city: 'Kawartha Lakes' },

    { name: 'Rural East Luther Grand Valley', city: 'East Luther Grand Valley' },

    { name: 'Rural East Gwillimbury', city: 'East Gwillimbury' },

    { name: 'Rural East Garafraxa', city: 'East Garafraxa' },

    { name: 'Rural Dutton/Dunwich', city: 'Dutton/Dunwich' },

    { name: 'Rural Dundas', city: 'Hamilton' },

    { name: 'Rural Douro-Dummer', city: 'Douro-Dummer' },

    { name: 'Rural Dalton', city: 'Kawartha Lakes' },

    { name: 'Rural Cramahe', city: 'Cramahe' },

    { name: 'Rural Clearview', city: 'Clearview' },

    { name: 'Rural Clarington', city: 'Clarington' },

    { name: 'Rural Chatsworth', city: 'Chatsworth' },

    { name: 'Rural Charlotteville', city: 'Norfolk' },

    { name: 'Rural Centre Wellington', city: 'Centre Wellington' },

    { name: 'Rural Central Elgin', city: 'Central Elgin' },

    { name: 'Rural Cavan Monaghan', city: 'Cavan Monaghan' },

    { name: 'Rural Carden', city: 'Kawartha Lakes' },

    { name: 'Rural Caledon', city: 'Caledon' },

    { name: 'Rural Burlington', city: 'Burlington' },

    { name: 'Rural Brock', city: 'Brock' },

    { name: 'Rural Brighton', city: 'Brighton' },

    { name: 'Rural Bradford West Gwillimbury', city: 'Bradford West Gwillimbury' },

    { name: 'Rural Blue Mountains', city: 'Blue Mountains' },

    { name: 'Rural Bexley', city: 'Kawartha Lakes' },

    { name: 'Rural Bayham', city: 'Bayham' },

    { name: 'Rural Barrie Southwest', city: 'Barrie' },

    { name: 'Rural Barrie Southeast', city: 'Barrie' },

    { name: 'Rural Aurora', city: 'Aurora' },

    { name: 'Rural Asphodel-Norwood', city: 'Asphodel-Norwood' },

    { name: 'Rural Ancaster', city: 'Hamilton' },

    { name: 'Rural Amaranth', city: 'Amaranth' },

    { name: 'Rural Alnwick/Haldimand', city: 'Alnwick/Haldimand' },

    { name: 'Rural Adjala-Tosorontio', city: 'Adjala-Tosorontio' },

    { name: 'Rural Adelaide Metcalfe', city: 'Adelaide Metcalfe' },

    { name: 'Runnymede-Bloor West Village', city: 'Toronto' },

    { name: 'Royal Orchard', city: 'Markham' },

    { name: 'Rougemount', city: 'Pickering' },

    { name: 'Rouge Woods', city: 'Richmond Hill' },

    { name: 'Rouge River Estates', city: 'Markham' },

    { name: 'Rouge Park', city: 'Pickering' },

    { name: 'Rouge Fairways', city: 'Markham' },

    { name: 'Rouge E11', city: 'Toronto' },

    { name: 'Rouge E10', city: 'Toronto' },

    { name: 'Rosemont', city: 'Adjala-Tosorontio' },

    { name: 'Roseland', city: 'Burlington' },

    { name: 'Rosedale-Moore Park', city: 'Toronto' },

    { name: 'Rosedale', city: 'Hamilton' },

    { name: 'Rosebank', city: 'Pickering' },

    { name: 'Rose', city: 'Burlington' },

    { name: 'Roncesvalles', city: 'Toronto' },

    { name: 'Rolston', city: 'Hamilton' },

    { name: 'Rolling Acres', city: 'Whitby' },

    { name: 'Rodney', city: 'West Elgin' },

    { name: 'Rockwood', city: 'Guelph/Eramosa' },

    { name: 'Rockcliffe-Smythe', city: 'Toronto' },

    { name: 'Riverdale', city: 'Hamilton' },

    { name: 'River Oaks', city: 'Oakville' },

    { name: 'Rideau', city: 'Ottawa' },

    { name: 'Richmond', city: 'Bayham' },

    { name: 'Rexdale-Kipling', city: 'Toronto' },

    { name: 'Regent Park', city: 'Toronto' },

    { name: 'Red Hill', city: 'Hamilton' },

    { name: 'Rayside-Balfour', city: 'Greater Sudbury' },

    { name: 'Raymerville', city: 'Markham' },

    { name: 'Rathwood', city: 'Mississauga' },

    { name: 'Randall', city: 'Hamilton' },

    { name: 'Rama First Nation', city: 'Rama First Nation 32' },

    { name: 'Raleigh', city: 'Hamilton' },

    { name: 'Raglan', city: 'Oshawa' },

    { name: 'Quinndale', city: 'Hamilton' },

    { name: 'Queensville', city: 'East Gwillimbury' },

    { name: 'Queen\'s Park', city: 'Barrie' },

    { name: 'Queen Street Corridor', city: 'Brampton' },

    { name: 'Pringle Creek', city: 'Whitby' },

    { name: 'Princeton', city: 'Blandford-Blenheim' },

    { name: 'Princess-Rosethorn', city: 'Toronto' },

    { name: 'Priceville', city: 'Grey Highlands' },

    { name: 'Prices Corners', city: 'Oro-Medonte' },

    { name: 'Pottageville', city: 'King' },

    { name: 'Port Whitby', city: 'Whitby' },

    { name: 'Port Stanley', city: 'Central Elgin' },

    { name: 'Port Severn', city: 'Severn' },

    { name: 'Port Rowan', city: 'Norfolk' },

    { name: 'Port Perry', city: 'Scugog' },

    { name: 'Port McNicoll', city: 'Tay' },

    { name: 'Port Hope', city: 'Port Hope' },

    { name: 'Port Glasgow', city: 'West Elgin' },

    { name: 'Port Franks', city: 'Lambton Shores' },

    { name: 'Port Dover', city: 'Norfolk' },

    { name: 'Port Credit', city: 'Mississauga' },

    { name: 'Port Burwell', city: 'Bayham' },

    { name: 'Port Bruce', city: 'Malahide' },

    { name: 'Port Albert', city: 'Ashfield-Colborne-Wawanosh' },

    { name: 'Pontypool', city: 'Kawartha Lakes' },

    { name: 'Plympton Wyoming', city: 'Plympton-Wyoming' },

    { name: 'Pleasant View', city: 'Toronto' },

    { name: 'Playter Estates-Danforth', city: 'Toronto' },

    { name: 'Plattsville', city: 'Blandford-Blenheim' },

    { name: 'Pinecrest', city: 'Oshawa' },

    { name: 'Pine Ridge', city: 'Guelph' },

    { name: 'Picton', city: 'Prince Edward County' },

    { name: 'Phelpston', city: 'Springwater' },

    { name: 'Penetanguishene', city: 'Penetanguishene' },

    { name: 'Pefferlaw', city: 'Georgina' },

    { name: 'Patterson', city: 'Vaughan' },

    { name: 'Parkwoods-Donalda', city: 'Toronto' },

    { name: 'Parkwood Gardens', city: 'Guelph' },

    { name: 'Parkview', city: 'Hamilton' },

    { name: 'Parkhill', city: 'North Middlesex' },

    { name: 'Paris', city: 'Brant' },

    { name: 'Palmerston-Little Italy', city: 'Toronto' },

    { name: 'Palmerston', city: 'Minto' },

    { name: 'Palmer', city: 'Burlington' },

    { name: 'Palgrave', city: 'Caledon' },

    { name: 'Palermo West', city: 'Oakville' },

    { name: 'Painswick South', city: 'Barrie' },

    { name: 'Painswick North', city: 'Barrie' },

    { name: 'Owen Sound', city: 'Owen Sound' },

    { name: 'Otterville', city: 'Norwich' },

    { name: 'Ottawa', city: 'Ottawa' },

    { name: 'Otonabee', city: 'Peterborough' },

    { name: 'Osgoode', city: 'Ottawa' },

    { name: 'Orwell', city: 'Malahide' },

    { name: 'Orono', city: 'Clarington' },

    { name: 'Orillia', city: 'Orillia' },

    { name: 'Orchard', city: 'Burlington' },

    { name: 'Orangeville', city: 'Orangeville' },

    { name: 'Onward Willow', city: 'Guelph' },

    { name: 'Omemee', city: 'Kawartha Lakes' },

    { name: 'Old University', city: 'Guelph' },

    { name: 'Old Oakville', city: 'Oakville' },

    { name: 'Old Milton', city: 'Milton' },

    { name: 'Old Markham Village', city: 'Markham' },

    { name: 'Observatory', city: 'Richmond Hill' },

    { name: 'Oakwood Village', city: 'Toronto' },

    { name: 'Oakwood', city: 'Kawartha Lakes' },

    { name: 'Oakridge', city: 'Toronto' },

    { name: 'Oakland', city: 'Brant' },

    { name: 'Oak Ridges Lake Wilcox', city: 'Richmond Hill' },

    { name: 'Oak Ridges', city: 'Richmond Hill' },

    { name: 'O\'Neill', city: 'Oshawa' },

    { name: 'O\'Connor-Parkview', city: 'Toronto' },

    { name: 'NW', city: 'St. Thomas' },

    { name: 'NW', city: 'Strathroy-Caradoc' },

    { name: 'Nottawa', city: 'Clearview' },

    { name: 'Norwood', city: 'Asphodel-Norwood' },

    { name: 'Norwich Town', city: 'Norwich' },

    { name: 'Northwood Park', city: 'Brampton' },

    { name: 'Northwood', city: 'Oshawa' },

    { name: 'Northwest Sandalwood Parkway', city: 'Brampton' },

    { name: 'Northwest Industrial Park', city: 'Guelph' },

    { name: 'Northwest Brampton', city: 'Brampton' },

    { name: 'Northwest Ajax', city: 'Ajax' },

    { name: 'Northwest', city: 'Barrie' },

    { name: 'Northglen', city: 'Oshawa' },

    { name: 'Northgate', city: 'Brampton' },

    { name: 'Northern Bruce Peninsula', city: 'Northern Bruce Peninsula' },

    { name: 'Northeast Ajax', city: 'Ajax' },

    { name: 'Northcrest', city: 'Peterborough' },

    { name: 'North St. James Town', city: 'Toronto' },

    { name: 'North Shore', city: 'Barrie' },

    { name: 'North Riverdale', city: 'Toronto' },

    { name: 'North Richvale', city: 'Richmond Hill' },

    { name: 'North End', city: 'Hamilton' },

    { name: 'Normanhurst', city: 'Hamilton' },

    { name: 'Norland', city: 'Kawartha Lakes' },

    { name: 'Norfolk', city: 'Norfolk' },

    { name: 'None', city: 'Ingersoll' },

    { name: 'Nobleton', city: 'King' },

    { name: 'Newtonbrook West', city: 'Toronto' },

    { name: 'Newtonbrook East', city: 'Toronto' },

    { name: 'Newcastle', city: 'Clarington' },

    { name: 'Newbury', city: 'Newbury' },

    { name: 'New Toronto', city: 'Toronto' },

    { name: 'New Sarum', city: 'Central Elgin' },

    { name: 'New Lowell', city: 'Clearview' },

    { name: 'New Glasgow', city: 'West Elgin' },

    { name: 'Neustadt', city: 'West Grey' },

    { name: 'Nepean', city: 'Ottawa' },

    { name: 'Nelson', city: 'Milton' },

    { name: 'NE', city: 'Strathroy-Caradoc' },

    { name: 'NE', city: 'St. Thomas' },

    { name: 'Nassagaweya', city: 'Milton' },

    { name: 'Nanticoke', city: 'Haldimand' },

    { name: 'Nairn', city: 'North Middlesex' },

    { name: 'Mt. Elgin', city: 'South-West Oxford' },

    { name: 'Mt Albert', city: 'East Gwillimbury' },

    { name: 'Mountview', city: 'Hamilton' },

    { name: 'Mountjoy Rural', city: 'Timmins' },

    { name: 'Mountainside', city: 'Burlington' },

    { name: 'Mount Salem', city: 'Malahide' },

    { name: 'Mount Pleasant West', city: 'Toronto' },

    { name: 'Mount Pleasant East', city: 'Toronto' },

    { name: 'Mount Olive-Silverstone-Jamestown', city: 'Toronto' },

    { name: 'Mount Hope', city: 'Hamilton' },

    { name: 'Mount Forest', city: 'Wellington North' },

    { name: 'Mount Elgin', city: 'South-West Oxford' },

    { name: 'Mount Dennis', city: 'Toronto' },

    { name: 'Mount Brydges', city: 'Strathroy-Caradoc' },

    { name: 'Mossley', city: 'Thames Centre' },

    { name: 'Moss Park', city: 'Toronto' },

    { name: 'Morriston', city: 'Puslinch' },

    { name: 'Morningside', city: 'Toronto' },

    { name: 'Moonstone', city: 'Oro-Medonte' },

    { name: 'Mono Mills', city: 'Caledon' },

    { name: 'Monaghan', city: 'Peterborough' },

    { name: 'Moffat', city: 'Milton' },

    { name: 'Mississauga Valleys', city: 'Mississauga' },

    { name: 'Minesing', city: 'Springwater' },

    { name: 'Mineola', city: 'Mississauga' },

    { name: 'Mimico', city: 'Toronto' },

    { name: 'Milton Heights', city: 'Milton' },

    { name: 'Milliken Mills West', city: 'Markham' },

    { name: 'Milliken Mills East', city: 'Markham' },

    { name: 'Milliken', city: 'Toronto' },

    { name: 'Millbrook', city: 'Cavan Monaghan' },

    { name: 'Mill Pond', city: 'Richmond Hill' },

    { name: 'Midland', city: 'Midland' },

    { name: 'Midhurst', city: 'Springwater' },

    { name: 'Middlesex Centre', city: 'Middlesex Centre' },

    { name: 'Middlemiss', city: 'Southwest Middlesex' },

    { name: 'Middlefield', city: 'Markham' },

    { name: 'Mewburn', city: 'Hamilton' },

    { name: 'Melbourne', city: 'Strathroy-Caradoc' },

    { name: 'Meaford', city: 'Meaford' },

    { name: 'Meadowvale Village', city: 'Mississauga' },

    { name: 'Meadowvale', city: 'Mississauga' },

    { name: 'Meadowlands', city: 'Hamilton' },

    { name: 'McQuesten', city: 'Hamilton' },

    { name: 'McLaughlin', city: 'Oshawa' },

    { name: 'McKillop Twp', city: 'Huron East' },

    { name: 'Markville', city: 'Markham' },

    { name: 'Markland Wood', city: 'Toronto' },

    { name: 'Markham Village', city: 'Markham' },

    { name: 'Markdale', city: 'Grey Highlands' },

    { name: 'Marchmont', city: 'Severn' },

    { name: 'Maple Leaf', city: 'Toronto' },

    { name: 'Maple', city: 'Vaughan' },

    { name: 'Manilla', city: 'Kawartha Lakes' },

    { name: 'Malvern', city: 'Toronto' },

    { name: 'Malton', city: 'Mississauga' },

    { name: 'Malahide', city: 'Malahide' },

    { name: 'Madoc', city: 'Brampton' },

    { name: 'Macassa', city: 'Hamilton' },

    { name: 'Lynhurst', city: 'Central Elgin' },

    { name: 'Lynden', city: 'Hamilton' },

    { name: 'Lynde Creek', city: 'Whitby' },

    { name: 'Lucan', city: 'Lucan Biddulph' },

    { name: 'Lorne Park', city: 'Mississauga' },

    { name: 'Loretto', city: 'Adjala-Tosorontio' },

    { name: 'Long Point', city: 'Norfolk' },

    { name: 'Long Branch', city: 'Toronto' },

    { name: 'Liverpool', city: 'Pickering' },

    { name: 'Little Portugal', city: 'Toronto' },

    { name: 'Little Lake', city: 'Barrie' },

    { name: 'Little Britain', city: 'Kawartha Lakes' },

    { name: 'Lisle', city: 'Adjala-Tosorontio' },

    { name: 'Lisgar', city: 'Mississauga' },

    { name: 'Lisgar', city: 'Hamilton' },

    { name: 'Lindsay', city: 'Kawartha Lakes' },

    { name: 'Limehouse', city: 'Halton Hills' },

    { name: 'Letitia Heights', city: 'Barrie' },

    { name: 'Legacy', city: 'Markham' },

    { name: 'Lefroy', city: 'Innisfil' },

    { name: 'Leaside', city: 'Toronto' },

    { name: 'Leamington', city: 'Leamington' },

    { name: 'Lawrence Park South', city: 'Toronto' },

    { name: 'Lawrence Park North', city: 'Toronto' },

    { name: 'Lawfield', city: 'Hamilton' },

    { name: 'LaSalle', city: 'Burlington' },

    { name: 'LaSalle', city: 'LaSalle' },

    { name: 'LaSalette', city: 'Norfolk' },

    { name: 'Larder/Virginiatown', city: 'Larder Lake' },

    { name: 'Lansing-Westgate', city: 'Toronto' },

    { name: 'Langton', city: 'Norfolk' },

    { name: 'Langstaff', city: 'Richmond Hill' },

    { name: 'Landsdale', city: 'Hamilton' },

    { name: 'Lambton Shores', city: 'Lambton Shores' },

    { name: 'Lambton Baby Point', city: 'Toronto' },

    { name: 'Lakeview Estates', city: 'Vaughan' },

    { name: 'Lakeview', city: 'Oshawa' },

    { name: 'Lakeview', city: 'Mississauga' },

    { name: 'Lakeshore', city: 'Barrie' },

    { name: 'Lakeshore', city: 'Hamilton' },

    { name: 'Lakeshore', city: 'Lakeshore' },

    { name: 'Lakefield', city: 'Smith-Ennismore-Lakefield' },

    { name: 'Lafontaine', city: 'Tiny' },

    { name: 'L\'Amoreaux', city: 'Toronto' },

    { name: 'Kortright Hills', city: 'Guelph' },

    { name: 'Komoka', city: 'Middlesex Centre' },

    { name: 'Kleinburg', city: 'Vaughan' },

    { name: 'Kirkfield', city: 'Kawartha Lakes' },

    { name: 'Kirkendall', city: 'Hamilton' },

    { name: 'Kintail', city: 'Ashfield-Colborne-Wawanosh' },

    { name: 'Kinmount', city: 'Kawartha Lakes' },

    { name: 'Kingsway South', city: 'Toronto' },

    { name: 'Kingsview Village-The Westway', city: 'Toronto' },

    { name: 'King City', city: 'King' },

    { name: 'Kincardine', city: 'Kincardine' },

    { name: 'Kilworth', city: 'Middlesex Centre' },

    { name: 'Kettle Point', city: 'Lambton Shores' },

    { name: 'Keswick South', city: 'Georgina' },

    { name: 'Keswick North', city: 'Georgina' },

    { name: 'Kernighan', city: 'Hamilton' },

    { name: 'Kentley', city: 'Hamilton' },

    { name: 'Kensington-Chinatown', city: 'Toronto' },

    { name: 'Kennedy Park', city: 'Toronto' },

    { name: 'Kennedy', city: 'Hamilton' },

    { name: 'Keelesdale-Eglinton West', city: 'Toronto' },

    { name: 'Kedron', city: 'Oshawa' },

    { name: 'Kanata', city: 'Ottawa' },

    { name: 'June Avenue', city: 'Guelph' },

    { name: 'Junction Area', city: 'Toronto' },

    { name: 'Jerseyville', city: 'Hamilton' },

    { name: 'Jerome', city: 'Hamilton' },

    { name: 'Jefferson', city: 'Richmond Hill' },

    { name: 'Janetville', city: 'Kawartha Lakes' },

    { name: 'Islington-City Centre West', city: 'Toronto' },

    { name: 'Islington Woods', city: 'Vaughan' },

    { name: 'Iroquois Ridge South', city: 'Oakville' },

    { name: 'Iroquois Ridge North', city: 'Oakville' },

    { name: 'Iroquoia Heights', city: 'Hamilton' },

    { name: 'Ionview', city: 'Toronto' },

    { name: 'Iona Station', city: 'Southwold' },

    { name: 'Iona', city: 'Southwold' },

    { name: 'Iona', city: 'Dutton/Dunwich' },

    { name: 'Innis-Shore', city: 'Barrie' },

    { name: 'Innerkip', city: 'East Zorra-Tavistock' },

    { name: 'Inglewood', city: 'Caledon' },

    { name: 'Ingersoll - South', city: 'Ingersoll' },

    { name: 'Ingersoll - North', city: 'Ingersoll' },

    { name: 'Ingersoll', city: 'Ingersoll' },

    { name: 'Industrial Sector', city: 'Hamilton' },

    { name: 'Inch Park', city: 'Hamilton' },

    { name: 'Ilderton', city: 'Middlesex Centre' },

    { name: 'Huttonville', city: 'Brampton' },

    { name: 'Hurontario', city: 'Mississauga' },

    { name: 'Huron-Kinloss', city: 'Huron-Kinloss' },

    { name: 'Huron Heights-Leslie Valley', city: 'Newmarket' },

    { name: 'Huntington', city: 'Hamilton' },

    { name: 'Humewood-Cedarvale', city: 'Toronto' },

    { name: 'Humbermede', city: 'Toronto' },

    { name: 'Humberlea-Pelmo Park W5', city: 'Toronto' },

    { name: 'Humberlea-Pelmo Park W4', city: 'Toronto' },

    { name: 'Humber Summit', city: 'Toronto' },

    { name: 'Humber Heights', city: 'Toronto' },

    { name: 'Howick Twp', city: 'Howick' },

    { name: 'Howard', city: 'Chatham-Kent' },

    { name: 'Horseshoe Valley', city: 'Oro-Medonte' },

    { name: 'Homeside', city: 'Hamilton' },

    { name: 'Holly', city: 'Barrie' },

    { name: 'Holland Landing', city: 'East Gwillimbury' },

    { name: 'Hockley', city: 'Adjala-Tosorontio' },

    { name: 'Historic Lakeshore Communities', city: 'Georgina' },

    { name: 'Hillsdale', city: 'Springwater' },

    { name: 'Hillsburgh', city: 'Erin' },

    { name: 'Hills of St Andrew', city: 'Aurora' },

    { name: 'Hillier', city: 'Prince Edward County' },

    { name: 'Hillcrest Village', city: 'Toronto' },

    { name: 'Hill Park', city: 'Hamilton' },

    { name: 'Highway 427', city: 'Brampton' },

    { name: 'Highland Creek', city: 'Toronto' },

    { name: 'Highbush', city: 'Pickering' },

    { name: 'High Park-Swansea', city: 'Toronto' },

    { name: 'High Park North', city: 'Toronto' },

    { name: 'Hiawatha First Nation', city: 'Hiawatha First Nation' },

    { name: 'Hensall', city: 'Bluewater' },

    { name: 'Henry Farm', city: 'Toronto' },

    { name: 'Heart Lake West', city: 'Brampton' },

    { name: 'Heart Lake East', city: 'Brampton' },

    { name: 'Heart Lake', city: 'Brampton' },

    { name: 'Headon', city: 'Burlington' },

    { name: 'Headford Business Park', city: 'Richmond Hill' },

    { name: 'Hay Twp', city: 'Bluewater' },

    { name: 'Hawkestone', city: 'Oro-Medonte' },

    { name: 'Havelock', city: 'Havelock-Belmont-Methuen' },

    { name: 'Hastings', city: 'Trent Hills' },

    { name: 'Harriston', city: 'Minto' },

    { name: 'Harrison', city: 'Milton' },

    { name: 'Harding', city: 'Richmond Hill' },

    { name: 'Hanover', city: 'Hanover' },

    { name: 'Hannon', city: 'Hamilton' },

    { name: 'Hanlon Industrial', city: 'Guelph' },

    { name: 'Hanlon Creek', city: 'Guelph' },

    { name: 'Hampton Heights', city: 'Hamilton' },

    { name: 'Hamilton Beach', city: 'Hamilton' },

    { name: 'Hallowell', city: 'Prince Edward County' },

    { name: 'Haldimand', city: 'Haldimand' },

    { name: 'Guthrie', city: 'Oro-Medonte' },

    { name: 'Gurnett', city: 'Hamilton' },

    { name: 'Guildwood', city: 'Toronto' },

    { name: 'Guelph South', city: 'Guelph' },

    { name: 'Grove East', city: 'Barrie' },

    { name: 'Grindstone', city: 'Burlington' },

    { name: 'Greenwood-Coxwell', city: 'Toronto' },

    { name: 'Greensville', city: 'Hamilton' },

    { name: 'Greensborough', city: 'Markham' },

    { name: 'Greeningdon', city: 'Hamilton' },

    { name: 'Greenford', city: 'Hamilton' },

    { name: 'Granton', city: 'Lucan Biddulph' },

    { name: 'Grange Hill East', city: 'Guelph' },

    { name: 'Grandview', city: 'Markham' },

    { name: 'Grand Valley', city: 'East Luther Grand Valley' },

    { name: 'Grand Bend', city: 'Lambton Shores' },

    { name: 'Grafton', city: 'Alnwick/Haldimand' },

    { name: 'Gowanstown', city: 'North Perth' },

    { name: 'Gourley', city: 'Hamilton' },

    { name: 'Gorham-College Manor', city: 'Newmarket' },

    { name: 'Goreway Drive Corridor', city: 'Brampton' },

    { name: 'Gore Industrial South', city: 'Brampton' },

    { name: 'Gore Industrial North', city: 'Brampton' },

    { name: 'Goderich Twp', city: 'Central Huron' },

    { name: 'Goderich Town', city: 'Goderich' },

    { name: 'Gloucester', city: 'Ottawa' },

    { name: 'Glenway Estates', city: 'Newmarket' },

    { name: 'Glenview', city: 'Hamilton' },

    { name: 'Glenfield-Jane Heights', city: 'Toronto' },

    { name: 'Glencoe', city: 'Southwest Middlesex' },

    { name: 'Glencairn', city: 'Adjala-Tosorontio' },

    { name: 'Glen Williams', city: 'Halton Hills' },

    { name: 'Glen Shields', city: 'Vaughan' },

    { name: 'Glen Abbey', city: 'Oakville' },

    { name: 'Gilkson', city: 'Hamilton' },

    { name: 'Gilford', city: 'Innisfil' },

    { name: 'Gilbert', city: 'Hamilton' },

    { name: 'Gibson', city: 'Hamilton' },

    { name: 'Gershome', city: 'Hamilton' },

    { name: 'Georgetown South', city: 'Halton Hills' },

    { name: 'Georgetown', city: 'Halton Hills' },

    { name: 'George South', city: 'South Bruce Peninsula' },

    { name: 'George North', city: 'South Bruce Peninsula' },

    { name: 'Genova', city: 'Lucan Biddulph' },

    { name: 'Garrison Village', city: 'Niagara-on-the-Lake' },

    { name: 'Garryowen', city: 'Chatham-Kent' },

    { name: 'Garafraxa Hill', city: 'East Luther Grand Valley' },

    { name: 'Gananoque', city: 'Gananoque' },

    { name: 'Gallanough', city: 'Vaughan' },

    { name: 'Gainsborough Twp', city: 'West Lincoln' },

    { name: 'Gage Park', city: 'Hamilton' },

    { name: 'Forest Glade', city: 'Windsor' },

    { name: 'Forest Hill South', city: 'Toronto' },

    { name: 'Forest Hill North', city: 'Toronto' },

    { name: 'Forest Heights', city: 'Hamilton' },

    { name: 'Forest Hill', city: 'Kitchener' },

    { name: 'Forest', city: 'Lambton Shores' },

    { name: 'Fonthill', city: 'Pelham' },

    { name: 'Flemingdon Park', city: 'Toronto' },

    { name: 'Flesherton', city: 'Grey Highlands' },

    { name: 'Flamborough East', city: 'Hamilton' },

    { name: 'Fisherville', city: 'Haldimand' },

    { name: 'Fergus', city: 'Centre Wellington' },

    { name: 'Feversham', city: 'Grey Highlands' },

    { name: 'Fenwick', city: 'Pelham' },

    { name: 'Fenelon Falls', city: 'Kawartha Lakes' },

    { name: 'Faraday Twp', city: 'Faraday' },

    { name: 'Fairport Beach', city: 'Pickering' },

    { name: 'Fairbank', city: 'Toronto' },

    { name: 'Fonthill', city: 'Pelham' },

    { name: 'Fenelon Falls', city: 'Kawartha Lakes' },

  ];


  


  const handleCitySelection = (selection) => {

    const selectedItem = selection.label.split(',')[0]; // Extract the neighborhood/city name

  

    if (selection.type === 'City') {

      setSearchQuery(''); // Clear search input

      setSelectedCity(selectedItem); // Apply city filter

      setSelectedNeighborhood(''); // Clear neighborhood when city is selected

    } else if (selection.type === 'Neighborhood') {

      setSearchQuery(''); // Clear search input

      setSelectedCity(''); // Clear city field since it's a neighborhood search

      setSelectedNeighborhood(selectedItem); // Apply neighborhood filter

    } else if (selection.type === 'Address' && selection.listingId) {

      // Redirect to the listing page for that particular address

      const addressSlug = selectedItem.replace(/ /g, '-').replace(/[^a-zA-Z0-9\-]/g, ''); // Clean up address for the URL

      const listingUrl = `/listing/${selection.listingId}/${addressSlug}`;

      window.location.href = listingUrl; // Redirect to the listing URL

    }

  

    setFilteredCities([]); // Clear dropdown

  };

  

  

  

  

  

  

  

  

  

  

  useEffect(() => {

    const handleClickOutside = (event) => {

      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {

        setFilteredCities([]); // Close the dropdown if clicked outside

      }

    };

  

    document.addEventListener('mousedown', handleClickOutside);

  

    return () => {

      document.removeEventListener('mousedown', handleClickOutside);

    };

  }, []);

  


  useEffect(() => {

    // Parse query parameters from the URL

    const queryParams = new URLSearchParams(window.location.search);


    // Set state based on URL parameters

    const city = queryParams.get('city') || 'All';

    const minPrice = parseInt(queryParams.get('minPrice'), 10) || 0;

    const maxPrice = parseInt(queryParams.get('maxPrice'), 10) || 10000000;

    const bedrooms = queryParams.get('bedrooms') || 'Any';

    const bathrooms = queryParams.get('bathrooms') || 'Any';

    const search = queryParams.get('search') || '';

    const page = parseInt(queryParams.get('page'), 10) || 1;


    setSelectedCity(city);

    setPriceRange({ min: minPrice, max: maxPrice });

    setSelectedBedrooms(bedrooms);

    setSelectedBathrooms(bathrooms);

    setSearchQuery(search);

    setCurrentPage(page);

  }, []);


  const fetchPageListings = async () => {

    setLoading(true);

    try {

      const pageOffset = (currentPage - 1) * listingsPerPage;

  

      const pageResponse = await axios.get('/listings', {

        params: {

          limit: listingsPerPage,

          offset: pageOffset,

          city: selectedCity !== 'All' ? selectedCity : null,

          community: selectedNeighborhood !== 'All' && selectedNeighborhood !== '' ? selectedNeighborhood : null,

          bedrooms: selectedBedrooms !== 'Any' ? selectedBedrooms : null,

          bathrooms: selectedBathrooms !== 'Any' ? selectedBathrooms : null,

          search: manualSearchQuery || null, // Include search query in the request

          minPrice: priceRange.min,

          maxPrice: priceRange.max,

        },

      });

      setFilteredListings(pageResponse.data); // Set the filtered listings

      setLoading(false);

    } catch (error) {

      console.error('Error fetching page listings:', error);

      setLoading(false);

    }

  };

  


  const [showSharePopup, setShowSharePopup] = useState(false);

  const [currentListingUrl, setCurrentListingUrl] = useState('');


  const handleShare = (e) => {

    e.preventDefault();

    

    const addressSlug = selectedListing.address

      .replace(/ /g, '-')

      .replace(/[^a-zA-Z0-9\-]/g, '');

    const unitNum = selectedListing.unit_num ? `-${selectedListing.unit_num}` : '';

    const link = `${window.location.origin}/listing/${selectedListing.ml_num}/${addressSlug}${unitNum}`;

    

    setCurrentListingUrl(link);


    if (window.innerWidth > 768) {  // Desktop

      setShowSharePopup(true);

    } else {  // Mobile

      if (navigator.share) {

        navigator.share({

          title: 'Check out this listing',

          url: link

        }).catch(console.error);

      } else {

        // Fallback for mobile browsers that don't support Web Share API

        setShowSharePopup(true);

      }

    }

  };

  

  

  

  

  

  

  


  useEffect(() => {

    const fetchPageListings = async () => {

      setLoading(true);

      try {

        const pageOffset = (currentPage - 1) * listingsPerPage;

        const pageResponse = await axios.get('/listings', {

          params: {

            limit: listingsPerPage,

            offset: pageOffset,

            city: selectedCity !== 'All' ? selectedCity : null,

            community: selectedNeighborhood !== 'All' && selectedNeighborhood !== '' ? selectedNeighborhood : null, // Add community filter

            bedrooms: selectedBedrooms !== 'Any' ? selectedBedrooms : null,

            bathrooms: selectedBathrooms !== 'Any' ? selectedBathrooms : null,

            search: searchQuery || null,

            minPrice: priceRange.min,

            maxPrice: priceRange.max,

          },

        });

        setFilteredListings(pageResponse.data);

        setLoading(false);

      } catch (error) {

        console.error('Error fetching page listings:', error);

        setLoading(false);

      }

    };

    

    

  

    fetchPageListings();

  }, [currentPage, selectedCity, selectedBedrooms, selectedBathrooms, priceRange, selectedNeighborhood]);  // `searchQuery` removed

  

  

  

  


  useEffect(() => {

    // After listings are loaded, update currentPage based on query params if necessary

    const queryParams = new URLSearchParams(window.location.search);

    const pageFromURL = parseInt(queryParams.get('page'), 10);

    if (pageFromURL && pageFromURL !== currentPage) {

      setCurrentPage(pageFromURL); // Correct the page after data is loaded

    }

  }, [loading]);


  

  


  const handlePageChange = (pageNumber) => {

    setCurrentPage(pageNumber);

    const queryParams = new URLSearchParams({

      city: selectedCity,

      minPrice: priceRange.min,

      maxPrice: priceRange.max,

      bedrooms: selectedBedrooms,

      bathrooms: selectedBathrooms,

      search: searchQuery,

      page: pageNumber

    }).toString();

    window.history.pushState(null, '', `/listings?${queryParams}`);

  };


  const toggleFilters = () => {

  setShowFilters((prevShowFilters) => !prevShowFilters);

};



const renderPagination = () => {

  if (loadingPagination) {

    return (

      <div className="loading-spinner">

        <div className="spinner"></div>

      </div>

    );

  }


  const totalPages = Math.ceil(totalListings / listingsPerPage); // Use totalListings

  const pageNumbers = [];

  for (let i = 1; i <= totalPages; i++) {

    pageNumbers.push(i);

  }


  return (

    <div>

      {pageNumbers.map(page => (

        <button

          key={page}

          className={currentPage === page ? 'active' : ''}

          onClick={() => handlePageChange(page)}

        >

          {page}

        </button>

      ))}

    </div>

  );

};



  


  useEffect(() => {

    const fetchClusterListings = async () => {

      setLoadingPagination(true);  // Show loading spinner for pagination

      try {

        // Fetch listings for clustering

        const clusterResponse = await axios.get('/listings', {

          params: {

            limit: maxListingsToShow,

            offset: 0,

            city: selectedCity !== 'All' ? selectedCity : null,

            community: selectedNeighborhood !== 'All' && selectedNeighborhood !== '' ? selectedNeighborhood : null,

            bedrooms: selectedBedrooms !== 'Any' ? selectedBedrooms : null,

            bathrooms: selectedBathrooms !== 'Any' ? selectedBathrooms : null,

            search: searchQuery || null,

            minPrice: priceRange.min,

            maxPrice: priceRange.max,

          },

        });

  

        // Fetch total count for pagination

        const totalResponse = await axios.get('/listings/count', {

          params: {

            city: selectedCity !== 'All' ? selectedCity : null,

            community: selectedNeighborhood !== 'All' && selectedNeighborhood !== '' ? selectedNeighborhood : null,

            bedrooms: selectedBedrooms !== 'Any' ? selectedBedrooms : null,

            bathrooms: selectedBathrooms !== 'Any' ? selectedBathrooms : null,

            search: searchQuery || null,

            minPrice: priceRange.min,

            maxPrice: priceRange.max,

          },

        });

  

        const totalCount = Math.min(totalResponse.data.totalCount, maxTotalListings);

        setTotalListings(totalCount);  // Dynamically set total listings

  

        if (isFirstLoad || currentPage === 1) {

          updateClusters(clusterResponse.data);  // Update map clusters

          setIsFirstLoad(false);

        }

      } catch (error) {

        console.error('Error fetching cluster listings:', error);

        setError(error.message);

      } finally {

        setLoadingPagination(false);  // Hide loading spinner after pagination is calculated

      }

    };

  

    fetchClusterListings();

  }, [selectedCity, selectedNeighborhood, selectedBedrooms, selectedBathrooms, searchQuery, priceRange, currentPage]);

    // Add selectedNeighborhood to the dependencies

  


  useEffect(() => {

    setCurrentPage(1);

    setFilteredListings([]);


    if (mapInstance.current) {

      mapInstance.current.setZoom(initialZoomLevel);

    }

  }, [selectedCity, selectedBedrooms, selectedBathrooms, searchQuery, priceRange]);

  



  const geocodeAddress = async (address, province = 'Ontario') => {

    const fullAddress = `${address}, ${province}, Canada`;


    if (geocodeCache.has(fullAddress)) {

      

      return geocodeCache.get(fullAddress);

    }


    try {

      const response = await axios.get(

        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(fullAddress)}&key=`

      );

      const { results } = response.data;

      if (results.length > 0) {

        const { lat, lng } = results[0].geometry.location;

        const position = { lat, lng };

        geocodeCache.set(fullAddress, position);

        return position;

      }

    } catch (error) {

      

    }


    return null;

  };


  const updateClusters = async (listingsToMark) => {

    if (markerClusterer.current) {

      markerClusterer.current.clearMarkers();

      markerClusterer.current = null;

    }

  

    const validMarkers = [];

    let geocodeApiCallCount = 0;

  

    // Go through listings one by one to ensure no more than 20 API calls are made

    for (let i = 0; i < listingsToMark.length; i++) {

      if (geocodeApiCallCount >= 20) {

        break; // Exit the loop once the API call limit is reached

      }

  

      const listing = listingsToMark[i];

      const { address } = listing;

  

      try {

        const position = await geocodeAddress(address, listing.municipality);

        if (position && !isNaN(position.lat) && !isNaN(position.lng)) {

          geocodeApiCallCount++; // Increment only when a valid position is returned

  

          const marker = new window.google.maps.Marker({

            position,

            title: address,

          });

  

          marker.addListener('click', () => {

            setSelectedListing(listing);

          });

  

          validMarkers.push(marker);

        }

      } catch (error) {

        console.error(`Failed to create marker for listing: ${address}`, error);

      }

    }

  

    

  };

  

  


  const formatPrice = (price) => {

    return `$${Number(price).toLocaleString()}`;

  };


  const handleFilterChange = (setter) => (value) => {

    setter(value);

  };


  const totalPages = Math.ceil(totalListings / listingsPerPage);


  const paginate = (pageNumber) => {

    setCurrentPage(pageNumber);

  

    // Update the URL to reflect the new page

    const queryParams = new URLSearchParams({

      city: selectedCity,

      minPrice: priceRange.min,

      maxPrice: priceRange.max,

      bedrooms: selectedBedrooms,

      bathrooms: selectedBathrooms,

      search: searchQuery,

      page: pageNumber  // Update the URL with the new page number

    }).toString();

  

    // Push the updated query string to the URL (without reloading the page)

    window.history.pushState(null, '', `/listings?${queryParams}`);

  };

  


  const getPageNumbers = () => {

    const maxPages = 7;

    let startPage = Math.max(1, currentPage - Math.floor(maxPages / 2));

    let endPage = startPage + maxPages - 1;


    if (endPage > totalPages) {

      endPage = totalPages;

      startPage = Math.max(1, endPage - maxPages + 1);

    }


    const pages = [];

    for (let i = startPage; i <= endPage; i++) {

      pages.push(i);

    }


    return pages;

  };


  const pageNumbers = getPageNumbers();


  const uniqueCities = initialCities;


  // Reset searchApplied when clearing the search filter

const clearFilters = () => {

  setPriceRange({ min: 0, max: 10000000 });

  setSelectedCity('All');

  setSelectedBedrooms('Any');

  setSelectedBathrooms('Any');

  setSearchQuery('');

  setSearchApplied(false); // Reset search applied state

  setCurrentPage(1);

  setFilteredListings([]);

};


const removeFilter = (filterType) => {

  if (filterType === 'city') {

    setSelectedCity('All');

  } else if (filterType === 'neighborhood') {

    setSelectedNeighborhood(''); // Clear the neighborhood

  } else if (filterType === 'price') {

    setPriceRange({ min: 0, max: 10000000 });

  } else if (filterType === 'bedrooms') {

    setSelectedBedrooms('Any');

  } else if (filterType === 'bathrooms') {

    setSelectedBathrooms('Any');

  } else if (filterType === 'search') {

    setSearchQuery('');

  }

  setCurrentPage(1);

  setFilteredListings([]);

};



  const handleImageClick = (e, listing) => {

    e.stopPropagation();

    

    const shouldBlur = listing.search_type !== 'IDX' && !user;

  

    if (shouldBlur) {

      // Save current filters and page in the URL

      const queryParams = new URLSearchParams({

        city: selectedCity,

        minPrice: priceRange.min,

        maxPrice: priceRange.max,

        bedrooms: selectedBedrooms,

        bathrooms: selectedBathrooms,

        search: searchQuery,

        page: currentPage

      }).toString();

  

      // Redirect to login with the correct base URL and query parameters

      const loginUrl = `/login?redirect=${encodeURIComponent(`/listings?${queryParams}`)}`;

      

      window.location.href = loginUrl;  // Redirect the user

      return;

    }

  

    setSelectedListing(listing);

  };


  const handleSearchKeyPress = (e) => {

    if (e.key === 'Enter') {

      console.log('Enter key pressed. Search query:', manualSearchQuery); // Log search query

      handleSearch(); // Trigger search

    }

  };

  

  



  const [manualSearchQuery, setManualSearchQuery] = useState('');


  const handleCityInputChange = (e) => {

    const input = e.target.value;

    setManualSearchQuery(input);


    // Clear the previous timeout if the user types again before 1 second

    if (searchTimeoutRef.current) {

        clearTimeout(searchTimeoutRef.current);

    }


    // Immediately clear the dropdown

    setFilteredCities([]);  // Clear suggestions to hide dropdown while waiting for the next search results


    // Only run the search after a 1-second delay

    searchTimeoutRef.current = setTimeout(() => {

        if (input.length >= 2) {

            // Match Cities

            const matchedCities = initialCities.filter((city) =>

                city.toLowerCase().startsWith(input.toLowerCase())

            );


            // Match Neighborhoods

            const matchedNeighborhoods = neighborhoods.filter((neighborhood) =>

                neighborhood.name.toLowerCase().startsWith(input.toLowerCase())

            );


            // Query matching addresses from the listings

            axios.get('/listings', {

                params: {

                    addressSearch: input,  // Send the input as part of the query

                    limit: 5  // Limit the number of address results to 5

                }

            }).then(response => {

                const matchedAddresses = response.data

                    .filter((listing) =>

                        listing.address.toLowerCase().includes(input.toLowerCase())  // Match anywhere in the address

                    )

                    .slice(0, 5)  // Limit to 5 address results

                    .map(listing => ({

                        label: `${listing.address}${listing.unit_num ? ` #${listing.unit_num}` : ''}, ${listing.municipality}, Ontario`,  // Add city and Ontario

                        type: 'Address',

                        listingId: listing.ml_num,  // Store the MLS number

                    }));


                // Combine suggestions but limit the total to 5

                const suggestions = [

                    ...matchedCities.map((city) => ({

                        label: `${city}, Ontario`,

                        type: 'City',

                    })),

                    ...matchedNeighborhoods.map((neighborhood) => ({

                        label: `${neighborhood.name}, ${neighborhood.city}, Ontario`,

                        type: 'Neighborhood',

                    })),

                    ...matchedAddresses

                ];


                // Limit total combined suggestions to 5

                setFilteredCities(suggestions.slice(0, 5));

            }).catch(error => {

                console.error('Error fetching address suggestions:', error);

            });

        } else {

            setFilteredCities([]);  // Clear suggestions if input is less than 2 characters

        }

    }, 1000);  // Set a 1-second delay

};



  

  // Add a new state to track if the search has been applied

const [searchApplied, setSearchApplied] = useState(false);


const handleSearch = () => {

  console.log('Search initiated. Search query:', manualSearchQuery); // Log search query


  setCurrentPage(1); // Reset to the first page

  setSearchApplied(true); // Mark search as applied


  const queryParams = new URLSearchParams({

    city: selectedCity,  // Use selectedCity here as well

    community: selectedNeighborhood !== 'All' && selectedNeighborhood !== '' ? selectedNeighborhood : null,

    minPrice: priceRange.min,

    maxPrice: priceRange.max,

    bedrooms: selectedBedrooms,

    bathrooms: selectedBathrooms,

    search: manualSearchQuery, // Pass manualSearchQuery for filtering

    page: 1, // Reset to page 1 on search

  }).toString();


  // Update the browser URL to reflect the search state

  window.history.pushState(null, '', `/listings?${queryParams}`);


  // Trigger the API call to fetch filtered listings (existing logic should handle this)

  fetchPageListings(); // This should already include search

};









  

  

  


  const renderImage = (listing) => {

    const mainImage = listing.main_image || (Array.isArray(listing.photo_urls) ? listing.photo_urls[0] : listing.photo_urls.split(',')[0]);

  

    // Determine if we should blur the image based on search_type

    const shouldBlur = listing.search_type !== 'IDX' && !user;

  

    return (

      <div className='image-container'>

        <img

          src={mainImage}

          alt="Listing"

          className={`listing-image ${shouldBlur ? 'blurred' : ''}`}

          onClick={(e) => {

            if (!shouldBlur) {

              handleImageClick(e, listing);

            }

          }}

        />

        {shouldBlur && (

          <div className="login-button-overlay">

            <a

              href="#"

              className="login-button"

              onClick={(e) => {

                e.preventDefault();

  

                // Log the MLS number to verify that we have it

                console.log("MLS Number (ml_num):", listing.ml_num);

  

                // Create URLSearchParams for the current filters, page, and MLS number

                const queryParams = new URLSearchParams({

                  city: selectedCity,

                  minPrice: priceRange.min,

                  maxPrice: priceRange.max,

                  bedrooms: selectedBedrooms,

                  bathrooms: selectedBathrooms,

                  search: searchQuery,

                  page: currentPage,

                  ml_num: listing.ml_num  // Append the MLS number correctly

                });

  

                // Convert queryParams to a string for the URL

                const queryString = queryParams.toString();

  

                // Log the query string to confirm ml_num is included

                console.log("Query String with MLS Number:", queryString);

  

                // Build the login URL and ensure encoding is correct

                const loginUrl = `/login?redirect=${encodeURIComponent(`/listings?${queryString}`)}`;

  

                // Log the final login URL

                console.log("Generated Login URL:", loginUrl);

  

                // Redirect to login page with the constructed URL

                window.location.href = loginUrl;

              }}

            >

              Login to view details

            </a>

          </div>

        )}

      </div>

    );

  };

  

  

  


  return (

    <div className='listing-search-container'>

      {error && (

        <div className='error-message'>

          Error fetching listings data: {error}

        </div>

      )}

      


      <div className='filter-bar'>

        <div className='filter-item price-range-filter'>

          <label>Price Range:</label>

          <div className="price-inputs">

            <input

              type="number"

              placeholder="Min"

              value={priceRange.min}

              onChange={(e) => handleFilterChange(setPriceRange)({ ...priceRange, min: e.target.value })}

              className="price-input"

            />

            <span> - </span>

            <input

              type="number"

              placeholder="Max"

              value={priceRange.max}

              onChange={(e) => handleFilterChange(setPriceRange)({ ...priceRange, max: e.target.value })}

              className="price-input"

            />

          </div>

        </div>

        <div className='filter-item'>

          <label>City:</label>

          <select

            value={selectedCity}

            onChange={(e) => handleFilterChange(setSelectedCity)(e.target.value)}

            className='city-select'

          >

            <option value='All'>Select City</option>

            {uniqueCities.map((city, index) => (

              <option key={index} value={city}>

                {city}

              </option>

            ))}

          </select>

        </div>

        <div className='filter-item'>

          <label>Bedrooms:</label>

          <select

            value={selectedBedrooms}

            onChange={(e) => handleFilterChange(setSelectedBedrooms)(e.target.value)}

            className='bedrooms-select'

          >

            <option value='Any'>Any</option>

            {initialBedrooms.map((bedrooms, index) => (

              <option key={index} value={bedrooms}>

                {bedrooms}

              </option>

            ))}

          </select>

        </div>

        <div className='filter-item'>

          <label>Bathrooms:</label>

          <select

            value={selectedBathrooms}

            onChange={(e) => handleFilterChange(setSelectedBathrooms)(e.target.value)}

            className='bathrooms-select'

          >

            <option value='Any'>Any</option>

            {initialBathrooms.map((bathrooms, index) => (

              <option key={index} value={bathrooms}>

                {bathrooms}

              </option>

            ))}

          </select>

        </div>

        

        

        <div className='filter-item search-filter'>

  <label>Search Address:</label>

  <div ref={dropdownRef} className="search-input-container">

    <input

      type='text'

      value={manualSearchQuery}  // Bind to manualSearchQuery

      onChange={handleCityInputChange}  // Update manualSearchQuery on input change

      onKeyPress={handleSearchKeyPress}  // Search when Enter is pressed

      placeholder='Search Address, Street Name, or City'

      className='search-input'

    />

    <button onClick={handleSearch} className="search-button">

      <FaSearch />

    </button>


    {/* City and Neighborhood suggestions dropdown */}

    {filteredCities.length > 0 && (

      <ul className="city-suggestions">

  {filteredCities.map((item, index) => {

    const startIndex = item.label.toLowerCase().indexOf(manualSearchQuery.toLowerCase());

    const endIndex = startIndex + manualSearchQuery.length;


    const beforeMatch = item.label.slice(0, startIndex);

    const matchText = item.label.slice(startIndex, endIndex);

    const afterMatch = item.label.slice(endIndex);


    return (

      <li key={index} onClick={() => handleCitySelection(item)}>

        <div className="suggestion-item">

          <span className="city-name">

            {beforeMatch}

            <strong>{matchText}</strong>

            {afterMatch}

          </span>

          <span className="city-label">{item.type}</span>

        </div>

      </li>

    );

  })}

</ul>


    

    )}

  </div>

</div>






      </div>

      {/* Mobile-specific filter section */}

      <div className='mobile-filter-section'>

        <button className='filter-toggle-btn' onClick={toggleMobileFilters}>

          Filter Your Search →

        </button>

        {showMobileFilters && (

          <div className='mobile-filter-options'>

            <div className='filter-item price-range-filter'>

              <label>Price Range:</label>

              <div className="price-inputs">

                <input

                  type="number"

                  placeholder="Min"

                  value={priceRange.min}

                  onChange={(e) => setPriceRange({ ...priceRange, min: e.target.value })}

                  className="price-input"

                />

                <span> - </span>

                <input

                  type="number"

                  placeholder="Max"

                  value={priceRange.max}

                  onChange={(e) => setPriceRange({ ...priceRange, max: e.target.value })}

                  className="price-input"

                />

              </div>

            </div>

            <div className='filter-item'>

              <label>City:</label>

              <select

                value={selectedCity}

                onChange={(e) => setSelectedCity(e.target.value)}

                className='city-select'

              >

                <option value='All'>Select City</option>

                {uniqueCities.map((city, index) => (

                  <option key={index} value={city}>

                    {city}

                  </option>

                ))}

              </select>

            </div>

            <div className='filter-item'>

              <label>Bedrooms:</label>

              <select

                value={selectedBedrooms}

                onChange={(e) => setSelectedBedrooms(e.target.value)}

                className='bedrooms-select'

              >

                <option value='Any'>Any</option>

                {initialBedrooms.map((bedrooms, index) => (

                  <option key={index} value={bedrooms}>

                    {bedrooms}

                  </option>

                ))}

              </select>

            </div>

            <div className='filter-item'>

              <label>Bathrooms:</label>

              <select

                value={selectedBathrooms}

                onChange={(e) => setSelectedBathrooms(e.target.value)}

                className='bathrooms-select'

              >

                <option value='Any'>Any</option>

                {initialBathrooms.map((bathrooms, index) => (

                  <option key={index} value={bathrooms}>

                    {bathrooms}

                  </option>

                ))}

              </select>

            </div>

          </div>

        )}

      </div>

      <div className='applied-filters-bar'>

  {selectedCity !== 'All' && selectedCity !== '' && (

    <button

      onClick={() => removeFilter('city')}

      className='filter-button'

    >

      City: {selectedCity} <span className='remove-filter'>X</span>

    </button>

  )}

  {selectedNeighborhood !== '' && selectedNeighborhood !== 'All' && (

    <button

      onClick={() => removeFilter('neighborhood')}

      className='filter-button'

    >

      Neighborhood: {selectedNeighborhood} <span className='remove-filter'>X</span>

    </button>

  )}

  {(priceRange.min !== 0 || priceRange.max !== 10000000) && (

    <button

      onClick={() => removeFilter('price')}

      className='filter-button'

    >

      Price: {formatPrice(priceRange.min)} - {formatPrice(priceRange.max)}{' '}

      <span className='remove-filter'>X</span>

    </button>

  )}

  {selectedBedrooms !== 'Any' && (

    <button

      onClick={() => removeFilter('bedrooms')}

      className='filter-button'

    >

      Bedrooms: {selectedBedrooms} <span className='remove-filter'>X</span>

    </button>

  )}

  {selectedBathrooms !== 'Any' && (

    <button

      onClick={() => removeFilter('bathrooms')}

      className='filter-button'

    >

      Bathrooms: {selectedBathrooms} <span className='remove-filter'>X</span>

    </button>

  )}

  {searchApplied && manualSearchQuery && selectedCity === 'All' && selectedNeighborhood === '' && (

    <button

      onClick={() => removeFilter('search')}

      className='filter-button'

    >

      Search: {manualSearchQuery} <span className='remove-filter'>X</span>

    </button>

  )}

  {(selectedCity !== 'All' ||

    selectedNeighborhood !== '' ||

    priceRange.min !== 0 ||

    priceRange.max !== 10000000 ||

    selectedBedrooms !== 'Any' ||

    selectedBathrooms !== 'Any' ||

    (searchApplied && manualSearchQuery && selectedCity === 'All' && selectedNeighborhood === '')) && (

    <button onClick={clearFilters} className='clear-filters-button'>

      Clear All

    </button>

  )}

</div>


      <div className='main-content'>


        <div className='listings-column'>

          <div className={`listing-grid ${isFirstLoad ? 'transition-container' : ''}`}>

            {loading ? (

              Array.from({ length: listingsPerPage }).map((_, index) => (

                <div key={index} className="listing-card loading-card">

                  {/* The loading animation is applied here */}

                </div>

              ))

            ) : filteredListings.length > 0 ? (

              filteredListings.map((listing) => {

              


                // Only blur listings that do not have search_type as 'IDX'

                const shouldBlur = listing.search_type !== 'IDX' && !user;

              

                return (

                  <div

                    key={listing.ml_num}

                    className={`listing-card ${shouldBlur ? 'blurred' : ''}`} // Apply 'blurred' class if not IDX

                    onClick={(e) => handleImageClick(e, listing)}

                    onMouseEnter={() => setHoveredListing(listing)}

                    onMouseLeave={() => setHoveredListing(null)}

                  >

                    {/* Render the image */}

                    {renderImage(listing)}

              

                    {/* Blur the listing content if necessary */}

                    <div className={`listing-content ${shouldBlur ? 'blurred' : ''}`}>

                      {/* The rest of your listing content goes here */}

                      <div className="listing-style-badge">

                        <span><a href="/contact">Buy and Get $5000 Cash Back</a></span>

                      </div>

              

                      <div className="listing-details">

                        <div className="listing-detail-item">

                          <BedroomIcon className="icon" />

                          <span className="with-words"> {listing.bedrooms || 'N/A'}</span>

                          <span className="without-words">{listing.bedrooms || 'N/A'}</span>

                        </div>

                        <div className="listing-detail-item">

                          <BathroomIcon className="icon" />

                          <span className="with-words"> {listing.bathrooms || 'N/A'}</span>

                          <span className="without-words">{listing.bathrooms || 'N/A'}</span>

                        </div>

                        <div className="listing-detail-item">

                          SQFT: {listing.square_feet || 'N/A'}

                        </div>

                      </div>

              

                      <div className="listing-title">

                        <div className="title-community">

                          <h3 className="street-address"> {listing.address} {listing.unit_num ? `#${listing.unit_num}` : ''}</h3>

                          <p className="community">

                            {listing.community}{' '}

                            <span className="separator-dot">•</span>{' '}

                            {listing.municipality}

                          </p>

                        </div>

                        <div className="listing-price">

                          {formatPrice(listing.price)}

                        </div>

                      </div>

                    </div>

              

                    {/* Optional: Show overlay if blurred */}

                    {!user && (listing.search_type === 'NULL' || listing.search_type === 'VOW') && (

                      <div className="login-button-overlay">

                        <a href="/login" className="login-button">

                          Login to View Listing

                        </a>

                      </div>

                    )}

                  </div>

                );

              })

            ) : (

              <div>No listings found for your criteria.</div>

            )}

          </div>


          <div className='pagination'>

            {currentPage > 1 && (

              <button onClick={() => paginate(currentPage - 1)}>&laquo;</button>

            )}


            {pageNumbers[0] > 1 && (

              <>

                <button onClick={() => paginate(1)}>1</button>

                <span>...</span>

              </>

            )}


            {pageNumbers.map((page) => (

              <button

                key={page}

                onClick={() => paginate(page)}

                className={`pagination-button ${

                  currentPage === page ? 'active' : ''

                }`}

              >

                {page}

              </button>

            ))}


            {pageNumbers[pageNumbers.length - 1] < totalPages && (

              <>

                <span>...</span>

                <button onClick={() => paginate(totalPages)}>

                  {totalPages}

                </button>

              </>

            )}


            {currentPage < totalPages && (

              <button onClick={() => paginate(currentPage + 1)}>&raquo;</button>

            )}

          </div>

        </div>

      </div>


      {selectedListing && (

        <>

          <div className='listing-popup-overlay' onClick={() => setSelectedListing(null)}></div>

          <div className='listing-popup'>

            <div className='banner'>

              <div className='banner-left'>

                <a href="#" className='share-link' onClick={handleShare}>

                  <span>Share</span> <FaShareAlt className='banner-icon' />

                </a>

              </div>

              <div className='banner-middle'>

                {[

                  { name: 'Overview', anchor: '#overview' },

                  { name: 'Sold/Listing History', anchor: '#listing-history' },

                  { name: 'Facts & Features', anchor: '#facts-features' },

                  { name: 'Payment Calculator', anchor: '#payment-calculator' },

                ].map((item, index) => (

                  <a key={index} href={item.anchor} className='banner-menu-item'>

                    {item.name}

                  </a>

                ))}

              </div>

              <div className='banner-right'>

                <button

                  className='popup-close-button'

                  onClick={() => setSelectedListing(null)}

                >

                  X

                </button>

              </div>

            </div>


            <div className='popup-content'>

              <ListingDetail ml_num={selectedListing.ml_num} />

            </div>

          </div>

        </>

      )}

      {showSharePopup && (

        <SharePopup

          onClose={() => setShowSharePopup(false)}

          listingUrl={currentListingUrl}

        />

      )}

    </div>

  );

};


export default Listings;

