import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../services/axios';
import ReCAPTCHA from 'react-google-recaptcha';
import './ContactForm.css';
import { recaptchaSiteKey } from '../services/constants';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    municipality: '',
    message: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false);
  const recaptchaRef = useRef(null);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
    setIsRecaptchaVerified(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.email && !formData.phoneNumber) {
      alert('Please provide either an email or a phone number.');
      return;
    }

    if (!recaptchaToken) {
      alert('Please complete the reCAPTCHA');
      return;
    }

    setIsLoading(true);

    try {
      const res = await axios.post('/sendEmail', {
        ...formData,
        recaptchaToken,
      });

      if (res.status === 200) {
        alert(res.data.message);
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          phoneNumber: '',
          municipality: '',
          message: '',
        });
        navigate('/thank-you');
      }
    } catch (err) {
      console.error(err);
      alert('Error sending email.');
    } finally {
      setRecaptchaToken('');
      setIsRecaptchaVerified(false);
      recaptchaRef.current.reset();
      setIsLoading(false);
    }
  };

  return (
    <div className='contact-form'>
      <form onSubmit={handleSubmit}>
        <div>
          <input
            type='text'
            name='firstName'
            value={formData.firstName}
            onChange={handleChange}
            placeholder='First Name'
            required
          />
        </div>
        <div>
          <input
            type='text'
            name='lastName'
            value={formData.lastName}
            onChange={handleChange}
            placeholder='Last Name'
            required
          />
        </div>
        <div>
          <input
            type='email'
            name='email'
            value={formData.email}
            onChange={handleChange}
            placeholder='Email'
          />
        </div>
        <div>
          <input
            type='tel'
            name='phoneNumber'
            value={formData.phoneNumber}
            onChange={handleChange}
            placeholder='Phone Number'
          />
        </div>
        <div>
          <input
            type='text'
            name='municipality'
            value={formData.municipality}
            onChange={handleChange}
            placeholder='Municipality'
          />
        </div>
        <div>
          <textarea
            name='message'
            value={formData.message}
            onChange={handleChange}
            placeholder="Tell us what you're looking for"
          ></textarea>
        </div>
        <div style={{ marginBottom: '20px' }}>
          <ReCAPTCHA
            sitekey={recaptchaSiteKey}
            onChange={handleRecaptchaChange}
            ref={recaptchaRef}
          />
        </div>
        <button
          className='submit-button'
          type='submit'
          disabled={isLoading || !isRecaptchaVerified}
        >
          {isLoading ? 'Processing...' : 'Send'}
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
