import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom'; // Use NavLink instead of Link
import './Navbar.css';
import logo from '../logo.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

const Navbar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

   // Adding the Google Tag (gtag.js) script dynamically
   useEffect(() => {
    // Load gtag.js script
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtag/js?id=AW-16529115435';
    document.head.appendChild(script);

    // Initialize the gtag function and configuration after script has loaded
    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', 'AW-16529115435');
    };

    // Google Tag conversion tracking for the '/thank-you' page
    window.addEventListener('load', function () {
      if (window.location.pathname.includes('/thank-you')) {
        window.gtag('event', 'conversion', {
          send_to: 'AW-16529115435/gk_kCLvT2NUZEKuS2ck9',
        });
      }
    });
  }, []);

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="logo-container">
          <NavLink to="/" className="logo-link">
            <img src={logo} alt="Modern Solution Realty" className="logo-image" />
          </NavLink>
        </div>
        <ul className={`nav-links ${isMobileMenuOpen ? 'mobile-menu-open' : ''}`}>
          <li><NavLink to="/" activeClassName="active-link" onClick={closeMobileMenu}>Home</NavLink></li>
          <li><NavLink to="/buy" activeClassName="active-link" onClick={closeMobileMenu}>Buy</NavLink></li>
          <li><NavLink to="/sell" activeClassName="active-link" onClick={closeMobileMenu}>Sell</NavLink></li>
          <li><NavLink to="/listings" activeClassName="active-link" onClick={closeMobileMenu}>Search</NavLink></li>
          <li><NavLink to="/contact" activeClassName="active-link" onClick={closeMobileMenu}>Contact</NavLink></li>
          <li><NavLink to="/commission-explained" activeClassName="active-link" onClick={closeMobileMenu}>Commission Explained</NavLink></li>
          <li><NavLink to="/articles" activeClassName="active-link" onClick={closeMobileMenu}>Articles</NavLink></li>
        </ul>
        <div className="hamburger-icon" onClick={toggleMobileMenu}>
          <FontAwesomeIcon icon={isMobileMenuOpen ? faTimes : faBars} />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
