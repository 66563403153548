import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom'; // <-- Add this line
import SEO from './SEO';
import GoogleReviews from './GoogleReviews';
import CommissionCalculator from './CommissionCalculator';
import ContactForm from './ContactForm'; // Import ContactForm component
import { AiFillStar } from 'react-icons/ai'; 
import { FaPlay } from 'react-icons/fa'; // Example for Play Icon
import googleLogo from '../assets/images/google-logo.webp'; 
import Footer from './Footer'; 
import './Home.css';
import ListingWidgetRegular from './ListingWidgetRegular';
import ListingWidget from './ListingWidget';
import CityWidget from './CityWidget';
import Listings from './Listings';
import Slider from 'react-slick'; // Import Slider for property listing display
import icon1 from '../assets/images/icon-1.webp';
import icon2 from '../assets/images/icon-2.webp';
import icon3 from '../assets/images/icon-3.webp';
import icon4 from '../assets/images/icon-4.webp';
import icon5 from '../assets/images/icon-5.webp';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Testimonials from './Testimonials';
import axios from '../services/axios';
import thestarLogo from '../assets/images/thestar-logo.png';
import AdvertisingPartners from './AdvertisingPartners';


const Home = () => {
  const [propertyListings, setPropertyListings] = useState([]); // State for property listings
  const [isVideoPlaying, setIsVideoPlaying] = useState(false); // To manage play button visibility
  const [showContactForm, setShowContactForm] = useState(false); // State to manage button visibility
  const videoRef = useRef(null); // Reference to the video element

  // Fetch property listings (this simulates your data fetching for properties)
  useEffect(() => {
    const fetchListings = async () => {
      const response = await axios.get('/listings'); // Replace with your actual API endpoint
      const data = response;
      setPropertyListings(data);
    };
    
    fetchListings();
  }, []);

  // Slider settings for the property listings
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 6000, 
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000, 
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const observerOptions = {
      threshold: 0.1,
    };

    const handleIntersection = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(
      handleIntersection,
      observerOptions
    );

    const elementsToAnimate = document.querySelectorAll('.animate-on-scroll');
    elementsToAnimate.forEach((element) => {
      observer.observe(element);
    });

    return () => {
      elementsToAnimate.forEach((element) => {
        observer.unobserve(element);
      });
    };
  }, []);

  const counters = [
    { value: '1%', text: 'Listing for Sellers', icon: icon1 },
    { value: '$5,000', text: 'Cashback for Buyers', icon: icon2 },
    { value: '2,100+', text: 'Properties Sold', icon: icon3 },
    { value: '$1,100,000+', text: 'Buyers Received in Cashback', icon: icon4 },
    { value: '$20,150,000+', text: 'Sellers Saved on Commission', icon: icon5 },
  ];

  // Function to play video and hide the play button
  const handlePlayVideo = () => {
    console.log("videoRef.current:" + videoRef.current);
    if (videoRef.current) {
      videoRef.current.play();
      setIsVideoPlaying(true); // Hide the play button
    }
  };

  const handleButtonClick = () => {
    setShowContactForm(true); // Hide buttons and show contact form
  };

  return (
    <div className="home-page">
      <SEO
        title="Sell for 1% Commission | Low Commission Real Estate | 1 Percent List Realty"
        description="Sell your home for just 1% commission with Modern Solution. Enjoy low commission real estate services and get $5000 cashback when you buy. Call now!"
        keywords={[
          '1% commission real estate',
          'low commission real estate',
          'sell for 1 percent',
          '1 Percent List Realty',
          '$5000 cashback',
          'Modern Solution Realty',
        ]}
        ogImage="https://modernsolution.ca/homepage-image.jpg"
        url="https://modernsolution.ca/"
      />

      {/* Video Section */}
      <div className="fullwidth-video-container">
        <div className="video-container">
          {/* Play Button - Only visible when video is not playing */}
          {!isVideoPlaying && (
            <div className="unique-play-button" onClick={handlePlayVideo}>
              <FaPlay className="unique-play-icon" />
            </div>
          )}
          {/* Video Element */}
          <video
            ref={videoRef}
            width="100%"
            height="auto"
            controls
            playsInline={true}
            preload="metadata"  // Prevents mobile browsers from preloading the video
            poster={require('../assets/images/video-poster.png')}
            onPlay={() => setIsVideoPlaying(true)} // Ensure play button disappears when playing
            onPause={() => setIsVideoPlaying(false)} // Optionally handle pause
          >
            <source src={require('../assets/videos/video.mp4')} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>


      {/* New Section */}
      <div className="home-page-under-video-heading">
        <h4 className="supertext">OUR PRODUCTS</h4> {/* Added supertext */}
        <h2 className="section-heading">Modern Solutions For Every Scenario</h2>
      </div>

      <div className="home-page-blue-section">
        <div className="home-page-column">
          <div className="home-page-icon-text-container">
            <div className="home-page-blue-icon-square">
              <i className="fas fa-home home-page-icon" style={{ color: '#5a8ca5' }}></i>
            </div>
            <h2><em>Buying a Home?</em></h2>
          </div>
          <p>Modern Solution agents are some of the most experienced in the real estate industry. They'll help you navigate today's market.</p>
          <Link to="/buy">
            <button className="home-page-cta-button">Get $5,000 Cashback</button>
          </Link>
        </div>
        <div className="home-page-column">
          <div className="home-page-icon-text-container">
            <div className="home-page-blue-icon-square">
              <i className="fas fa-sign home-page-icon" style={{ color: '#5a8ca5' }}></i>
            </div>
            <h2><em>Selling Your Home?</em></h2>
          </div>
          <p>We are experts in pricing, marketing and selling your home so you get the best results. Our commission is among the lowest in the GTA.</p>
          <Link to="/sell">
            <button className="home-page-cta-button">List Your Home for 1%</button>
          </Link>
        </div>
      </div>

      <div className="two-column-section">
        <div className="left-column">
          {/* Commission Calculator */}
          <div className="white-container">
            <div className="calculator-headings">
              <h2>On Average, our clients saved <span style={{ color: '#9cdcf8' }}>$18,000</span>.</h2>
              <h3>How Much Will You Save?</h3>
            </div>
            <CommissionCalculator />
          </div>


          <Link to="/contact">
            <button className="home-page-cta-button free-home-evaluation-button">Free Market Evaluation</button>
          </Link>


          {/* Add Star Ratings and Review Count */}
<a href="https://g.co/kgs/XYNyUNZ" target="_blank" rel="noopener noreferrer" className="review-header-link">
  <div className="review-header">
    <div className="stars-row">
      <AiFillStar className="star-icon" />
      <AiFillStar className="star-icon" />
      <AiFillStar className="star-icon" />
      <AiFillStar className="star-icon" />
      <AiFillStar className="star-icon" />
    </div>
    <p className="review-count">Based on 223 Reviews</p>
    <img src={googleLogo} alt="Google Logo" className="google-logo" />
  </div>
</a>


          {/* Testimonials */}
          <div className="home-page-testimonials">
            <Testimonials />
          </div>

          {/* Counter Section */}

          <div className="counter-section">
          <h2>Results</h2>
            <div className="counter-row">
              {counters.slice(0, 3).map((counter, index) => (
                <div key={index} className="counter-box">
                  <img src={counter.icon} alt={`icon-${index + 1}`} className="counter-icon" />
                  <h3>{counter.value}</h3>
                  <p>{counter.text}</p>
                </div>
              ))}
            </div>
            <div className="counter-row">
              {counters.slice(3, 5).map((counter, index) => (
                <div key={index} className="counter-box">
                  <img src={counter.icon} alt={`icon-${index + 1}`} className="counter-icon" />
                  <h3>{counter.value}</h3>
                  <p>{counter.text}</p>
                </div>
              ))}
            </div>
          </div>

          
        </div>
        
        <h2 className="contact-heading">Contact Us</h2>
        <ContactForm />
        <ListingWidget />


<div className="city-links-section">
<CityWidget />

</div>







{/* Toronto Star Section */}
<div className="home-page-toronto-star">
  <img src={thestarLogo} alt="The Star Logo" className="thestar-logo" />
  <h2>Modern Solution Featured in Toronto Star</h2>
  <p>In a real estate world where outdated commission rates have long been the norm, Modern Solution Realty Inc. is proud to offer a refreshing and cost-effective alternative.</p>
  <p>Traditional commission structures, often hovering around five to six per cent, have burdened home sellers with exorbitant fees. For instance, selling a $1,000,000 home with a standard five per cent commission could cost a staggering $50,000 — an expense that seems increasingly unreasonable in today’s dynamic market.</p>
  <p>Hamid Intezam, founder and CEO of Modern Solution Realty Inc., Brokerage said, “Since our inception in 2014, we have been dedicated to challenging the status quo with our innovative one per cent commission model. This modern approach has allowed us to successfully sell over 2,400 homes totaling more than $3 billion sales, saving our clients a remarkable $25 million in commission fees. Modern Solution is also thrilled to offer a $5,000 cashback from our brokerage commission to those who purchase a home through us.”</p>
  <p>At Modern Solution Realty, there is a commitment to providing a full-service experience without compromising on quality. The model is designed to address the shortcomings of outdated traditional and flat fee structures.</p>
  
  <a href="https://www.thestar.com/sponsored-sections/a-modern-solution-approach-to-real-estate-with-one-per-cent-commission/article_1ca3e32e-6eec-11ef-84ea-53f224f162c8.html" target="_blank" rel="noopener noreferrer" className="read-more-link">Read More</a>
</div>

<div className="home-page-lets-talk" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/lets-talk.jpg)` }}>
  <h2>Let's Talk</h2>
  <p>When you're ready, a Modern Solution Realty agent is available to help you and answer any questions you may have.</p>
  <button className="home-page-cta-button" onClick={() => window.location.href = 'tel:(905) 897-5000'}>
  Give us a call
</button>

</div>

{/* Map Section */}

<h2 className="service-area-heading">Service Area</h2>
<div className="home-page-map-section">
  <img src={`${process.env.PUBLIC_URL}/map.png`} alt="Location map" className="home-page-map" />
</div>

      </div>


      <Footer />
      
    </div>
  );
};



export default Home;
