import React, { useState } from 'react';
import { PiCaretDownThin } from 'react-icons/pi'; // Importing an arrow icon from react-icons
import './SEOCities.css';

const SEOCities = () => {
  const [showMore, setShowMore] = useState(false);

  const cities = [
    'Adjala-Tosorontio', 'Ajax', 'Aurora', 'Barrie', 'Brampton', 'Brock', 'Burlington',
    'Caledon', 'Cambridge', 'Centre Wellington', 'Chatsworth', 'Clarington', 'Collingwood',
    'East Gwillimbury', 'East Luther Grand Valley', 'Erin', 'Essa', 'Fort Erie',
    'Galway-Cavendish and Harvey', 'Gananoque', 'Georgian Bay', 'Georgian Bluffs',
    'Georgina', 'Grimsby', 'Guelph', 'Guelph/Eramosa', 'Haldimand', 'Halton Hills',
    'Hamilton', 'Innisfil', 'Kawartha Lakes', 'King', 'Kingston', 'Kitchener',
    'Lincoln', 'London', 'Markham', 'Midland', 'Milton', 'Mississauga', 'New Tecumseth',
    'Newmarket', 'Niagara Falls', 'Niagara-on-the-Lake', 'Oakville', 'Orangeville',
    'Orillia', 'Oro-Medonte', 'Oshawa', 'Ottawa', 'Owen Sound', 'Penetanguishene',
    'Peterborough', 'Pickering', 'Scugog', 'Severn', 'Shelburne',
    'Tiny', 'Toronto', 'Vaughan', 'Wainfleet', 'Waterloo', 'Welland',
    'Whitby', 'Whitchurch-Stouffville', 'Woolwich'
  ];

  const displayedCities = showMore ? cities : cities.slice(0, 20);

  return (
    <div className="seo-cities">
      <h2>Homes for Sale in Ontario</h2>
      <div className="seo-cities-grid">
        {displayedCities.map((city, index) => (
          <a 
            key={index} 
            href={`/listings?city=${encodeURIComponent(city.charAt(0).toUpperCase() + city.slice(1).toLowerCase())}`}

            className="city-link"
          >
            Homes for sale in {city}
          </a>
        ))}
      </div>
      {!showMore && (
        <button className="read-more-btn" onClick={() => setShowMore(true)}>
          Read More <PiCaretDownThin className="down-arrow" />
        </button>
      )}
    </div>
  );
};

export default SEOCities;
