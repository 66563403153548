import React, { useState, useEffect, useRef } from 'react';
import axios from '../services/axios';
import './ListingWidget.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const ListingWidget = () => {
  const [listings, setListings] = useState([]);
  const sliderRef = useRef(null);
  const isDraggingRef = useRef(false);
  const startXRef = useRef(0);
  const scrollLeftRef = useRef(0);

  // Fisher-Yates Shuffle algorithm to randomize the listings array
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  useEffect(() => {
    const fetchListings = async () => {
      try {
        const response = await axios.post('/listings/bulk'); // Fetching listings without specifying MLS numbers
        const shuffledListings = shuffleArray(response.data); // Shuffle the listings before setting the state
        setListings(shuffledListings);
      } catch (error) {
        console.error('Error fetching listings:', error);
      }
    };

    fetchListings();
  }, []);

  const scrollLeft = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: -300,
        behavior: 'smooth',
      });
    }
  };

  const scrollRight = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: 300,
        behavior: 'smooth',
      });
    }
  };

  const handleMouseDown = (e) => {
    isDraggingRef.current = true;
    sliderRef.current.classList.add('dragging');
    startXRef.current = e.pageX - sliderRef.current.offsetLeft;
    scrollLeftRef.current = sliderRef.current.scrollLeft;
  };

  const handleMouseMove = (e) => {
    if (!isDraggingRef.current) return;
    e.preventDefault();
    const x = e.pageX - sliderRef.current.offsetLeft;
    const walk = (x - startXRef.current) * 2;
    sliderRef.current.scrollLeft = scrollLeftRef.current - walk;
  };

  const handleMouseUp = () => {
    isDraggingRef.current = false;
    sliderRef.current.classList.remove('dragging');
  };

  const handleTouchStart = (e) => {
    isDraggingRef.current = true;
    sliderRef.current.classList.add('dragging');
    startXRef.current = e.touches[0].pageX - sliderRef.current.offsetLeft;
    scrollLeftRef.current = sliderRef.current.scrollLeft;
  };

  const handleTouchMove = (e) => {
    if (!isDraggingRef.current) return;
    e.preventDefault();
    const x = e.touches[0].pageX - sliderRef.current.offsetLeft;
    const walk = (x - startXRef.current) * 2;
    sliderRef.current.scrollLeft = scrollLeftRef.current - walk;
  };

  const handleTouchEnd = () => {
    isDraggingRef.current = false;
    sliderRef.current.classList.remove('dragging');
  };

  const formatPrice = (price, propertyType) => {
    const formattedPrice = `$${Number(price).toLocaleString()}`;
    return propertyType.toLowerCase() === 'rental' ? `${formattedPrice}/month` : formattedPrice;
  };

  if (!listings.length) {
    return <div>Loading...</div>;
  }

  return (
    <div className='custom-listing-container'>
      <h2>Featured <span className='partners-text'>Listings</span></h2>
      <div className='divider'></div>
      <div className='slider-wrapper'>
        <button onClick={scrollLeft} className='slider-button left'>
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>

        <div
          className='slider'
          ref={sliderRef}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          {listings.concat(listings).map((listing, index) => {
            const photoUrls = listing.photo_urls ? listing.photo_urls : [];
            const firstPhotoUrl = photoUrls.length > 0 ? photoUrls[0] : '';

            const formattedAddress = listing.address.toLowerCase().replace(/\s+/g, '-');

            return (
              <Link
                to={`/listing/${listing.ml_num}/${formattedAddress}`}
                key={index}
                className='custom-listing-column'
              >
                <div className='image-container'>
                  {firstPhotoUrl ? (
                    <img src={firstPhotoUrl} alt='Listing Image' />
                  ) : (
                    <p>Image not available</p>
                  )}

                  <div className='overlay-icons'>
                    <span className='overlay-button'>
                      {listing.address}
                    </span>
                    <div className='beds-baths-container'>
                      <span className='overlay-button'>
                        {listing.bedrooms} Beds
                      </span>
                      <span className='overlay-button'>
                        {listing.bathrooms} Baths
                      </span>
                    </div>
                    <span className='overlay-button'>
                      {formatPrice(listing.price, listing.property_type)}
                    </span>
                  </div>

                  <Link to="/contact" className="list-button">
                    Buy and Get $5000 Cash Back
                  </Link>
                </div>
              </Link>
            );
          })}
        </div>
        <button onClick={scrollRight} className='slider-button right'>
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
    </div>
  );
};

export default ListingWidget;
